import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Service } from '../types';

export const deleteService = ({ serviceId }: { serviceId: number }) => {
  return axios.delete(`/service/DeleteService/${serviceId}`);
};

type UseDeleteServiceOptions = {
  config?: MutationConfig<typeof deleteService>;
};

export const useDeleteService = ({ config }: UseDeleteServiceOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async deletedService => {
      // Query key updated
      await queryClient.cancelQueries(['services']);

      const previousLocations = queryClient.getQueryData<Service[]>(['services']);

      queryClient.setQueryData(
        ['services'],
        previousLocations?.filter(display => display?.serviceId !== deletedService.serviceId)
      );

      return { previousLocations };
    },
    onError: (_, __, context: any) => {
      if (context?.previousLocations) {
        queryClient.setQueryData(['services'], context.previousLocations);
      }

      toast({
        variant: 'destructive',
        title: 'Failed to delete service',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['services']);
      queryClient.refetchQueries(['services']);

      toast({
        variant: 'success',
        title: 'Service deleted successfully!',
      });
    },
    ...config,
    mutationFn: ({ serviceId }) => deleteService({ serviceId }),
  });
};
