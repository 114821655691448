import { Suspense, useEffect } from 'react';
import { Outlet, RouteObject, useLocation } from 'react-router-dom';
import Layout from '@/components/layout';
// import { lazyImport } from '@/utils/lazyImport';
import { useLocationStore } from '@/stores/locationStore';

// const { Search } = lazyImport(() => import('@/features/venue/routes/Search'), 'Search');
// const { Venue } = lazyImport(() => import('@/features/venue/routes/Venue'), 'Venue');
// const { Venues } = lazyImport(() => import('@/features/venue/routes/Venues'), 'Venues');
// const { VenueCreate } = lazyImport(() => import('@/features/venue/routes/VenueCreate'), 'VenueCreate');
// const { VenueEdit } = lazyImport(() => import('@/features/venue/routes/VenueEdit'), 'VenueEdit');

// const { Locations } = lazyImport(() => import('@/features/location/routes/Locations'), 'Locations');
// const { Services } = lazyImport(() => import('@/features/service/routes/Services'), 'Services');

// const { Users } = lazyImport(() => import('@/features/user/routes/Users'), 'Users');
// const { UserCreate } = lazyImport(() => import('@/features/user/routes/UserCreate'), 'UserCreate');
// const { UserEdit } = lazyImport(() => import('@/features/user/routes/UserEdit'), 'UserEdit');

import { Search } from '@/features/venue';
import { Venue } from '@/features/venue';
import { Venues } from '@/features/venue';
import { VenueCreate } from '@/features/venue/routes/VenueCreate';
import { VenueEdit } from '@/features/venue/routes/VenueEdit';

import { Locations } from '@/features/location/routes/Locations';
import { Services } from '@/features/service/routes/Services';

import { Users } from '@/features/user/routes/Users';
import { UserCreate } from '@/features/user/routes/UserCreate';
import { UserEdit } from '@/features/user/routes/UserEdit';

const App = () => {
  const setLocation = useLocationStore(state => state.setLocation);
  const location = useLocation();

  // Trigger route change so I resize the iframe when embedded
  useEffect(() => {
    console.log('navigate')
    setLocation(location.pathname);
  }, [location]);

  return (
    <Layout>
      <Suspense fallback={<div className="h-full w-full flex items-center justify-center"></div>}>
        <Outlet />{' '}
      </Suspense>
    </Layout>
  );
};

export const adminRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '*', element: <Search /> },
      { path: '/', element: <Search /> },
      { path: '/venues/search', element: <Search /> },
      { path: '/venues/:id', element: <Venue /> },
      { path: '/venues', element: <Venues /> },
      { path: '/venues/create', element: <VenueCreate /> },
      { path: '/venues/edit/:id', element: <VenueEdit /> },

      { path: '/services', element: <Services /> },
      { path: '/locations', element: <Locations /> },

      { path: '/users', element: <Users /> },
      { path: '/users/create', element: <UserCreate /> },
      { path: '/users/edit/:id', element: <UserEdit /> },
    ],
  },
];
