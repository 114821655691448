import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Location } from '../types';

export const deleteLocation = ({ locationId }: { locationId: number }) => {
  return axios.delete(`/location/DeleteLocation/${locationId}`);
};

type UseDeleteLocationOptions = {
  config?: MutationConfig<typeof deleteLocation>;
};

export const useDeleteLocation = ({ config }: UseDeleteLocationOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async deletedLocation => {
      // Query key updated
      await queryClient.cancelQueries(['locations']);

      const previousLocations = queryClient.getQueryData<Location[]>(['locations']);

      queryClient.setQueryData(
        ['locations'],
        previousLocations?.filter(display => display?.locationId !== deletedLocation.locationId)
      );

      return { previousLocations };
    },
    onError: (_, __, context: any) => {
      if (context?.previousLocations) {
        queryClient.setQueryData(['locations'], context.previousLocations);
      }

      toast({
        variant: 'destructive',
        title: 'Failed to delete location',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
      queryClient.refetchQueries(['locations']);

      toast({
        variant: 'success',
        title: 'Location deleted successfully!',
      });
    },
    ...config,
    mutationFn: ({ locationId }) => deleteLocation({ locationId }),
  });
};
