import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { VenueExtra } from '../types';

export const getVenueExtra = async ({ venueId }: { venueId: number }): Promise<VenueExtra[]> => {
  return axios.get(`/venueExtra/${venueId}`);
};

type QueryFnType = typeof getVenueExtra;

type UseVenueExtraOptions = {
  venueId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useVenueExtra = ({ venueId, config }: UseVenueExtraOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['venueExtra', venueId],
    queryFn: () => getVenueExtra({ venueId }),
  });
};
