import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Venue } from '../types';
import storage from '@/utils/storage';

export type CreateVenueDTO = {
  venueName: string;
  address: string;
  locationId: number;
  exhibitorSpaceIndoor?: number;
  exhibitorSpaceOutdoor?: number;
  exhibitorMaxExhibitors?: number;
  exhibitorMaxVisitors?: number;
  exhibitorHalls?: number;
  exhibitorSpaceLargestHall?: number;
  conferenceRooms?: number;
  conferenceLargestRoom?: number;
  conferenceMaxDelegates?: number;
  onsiteHotelRooms?: number;
  offsiteHotelRooms3star?: number;
  offsiteHotelRooms4star?: number;
  offsiteHotelRooms5star?: number;
  parkingLots?: number;
  restaurantTotalSeats?: number;
  restaurantCount?: number;
  restaurantLargestSeat?: number;
  mainImage?: string;
  description?: string;
  published?: boolean;
};

export const createVenue = (data: CreateVenueDTO): Promise<Venue> => {
  const venueGroupId = storage.getUser()?.venueGroupId; // Move this logic to BE

  return axios.post(`/venue`, {
    ...data,
    venueGroupId,
  });
};

type UseCreateVenueDTO = {
  config?: MutationConfig<typeof createVenue>;
};

export const useCreateVenue = ({ config }: UseCreateVenueDTO) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async newVenue => {
      await queryClient.cancelQueries(['venues']);

      const previousVenues = queryClient.getQueryData<Venue[]>(['venues']);

      queryClient.setQueryData(['venues'], [...(previousVenues || []), newVenue]);

      return { previousVenues };
    },
    onError: (_, __, context: any) => {
      if (context?.previousVenues) {
        queryClient.setQueryData(['venues'], context.previousVenues);
      }
      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['venues']);
      queryClient.refetchQueries(['venues']);
    },
    ...config,
    mutationFn: (data: any) =>
      createVenue({
        ...data,
      }),
  });
};
