import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '../types';

export const getUser = async ({ userId }: { userId: number }): Promise<User> => {
  return axios.get(`/user/${userId}`);
};

export const getUserByGUID = async ({ userGUID }: { userGUID?: string }): Promise<User> => {
  return axios.get(`/0/user/guid/${userGUID}`);
};

type QueryFnType = typeof getUser;

type UseUserOptions = {
  userId?: number;
  userGUID?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useUser = ({ userId, userGUID, config }: UseUserOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['user', userId],
    queryFn: () => (userId ? getUser({ userId }) : getUserByGUID({ userGUID })),
  });
};
