import { Image } from 'lucide-react';
import { VenueExtra } from '@/features/venueExtra/types';
import { ImageFileToolbar } from './ImageFileToolbar';
import { fileToDataURL } from '@/utils/fileToDataURL';

type ImageGalleryProps = {
  images?: VenueExtra[] | Partial<VenueExtra>[] | any[];
} & (
  | {
      editMode: true;
      onChange: (images: VenueExtra[] | Partial<VenueExtra>[]) => void;
    }
  | {
      editMode: false;
      onChange?: never;
    }
);

const defaultImages: Partial<VenueExtra>[] = [
  {
    name: 'main',
    type: 'image',
  },
  {
    name: 'sec-1',
    type: 'image',
  },
  {
    name: 'sec-2',
    type: 'image',
  },
  {
    name: 'sec-3',
    type: 'image',
  },
  {
    name: 'sec-4',
    type: 'image',
  },
];

export function ImageGallery({ images = [], editMode = false, onChange }: ImageGalleryProps) {
  async function handleUpload(venueExtra: Partial<VenueExtra>) {
    const index = images.findIndex(image => image.name === venueExtra.name);
    if (index !== -1) {
      venueExtra.file = await fileToDataURL(venueExtra.file as any);
      onChange?.(images.map((image, i) => (i === index ? venueExtra : image)));
    } else {
      venueExtra.file = await fileToDataURL(venueExtra.file as any);
      onChange?.([...images, venueExtra]);
    }
  }

  function handleDelete(venueExtra: Partial<VenueExtra>) {
    const index = images.findIndex(image => image.name === venueExtra.name);
    if (index !== -1) {
      venueExtra.value = '';
      venueExtra.file = null;
      onChange?.(images.map((image, i) => (i === index ? venueExtra : image)));
    }
  }

  const collectedImages = defaultImages.map(image => {
    const found = images.find(i => i.name === image.name);
    return found ?? image;
  });

  const mainImage = collectedImages.find(image => image.name === 'main');

  if (editMode) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 h-96 max-h-96   rounded-lg overflow-hidden">
        <div className="h-96 relative">
          <div className="absolute top-2 left-2">
            <ImageFileToolbar onDelete={handleDelete} onChange={handleUpload} venueExtra={mainImage as VenueExtra} />
          </div>
          {mainImage?.value ? (
            <img src={mainImage?.value} alt="Main image" className="w-full h-96 object-cover" />
          ) : (
            <div className="w-full  object-cover h-full  bg-accent flex items-center justify-center">
              <Image className="h-12 w-12 m-auto text-muted-foreground" />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-2">
          {collectedImages
            .filter(image => image.name !== 'main')
            .splice(0, 4)
            .map(image => (
              <div className="relative h-full w-full" key={image.name}>
                <div className="absolute top-2 left-2">
                  <ImageFileToolbar onDelete={handleDelete} onChange={handleUpload} venueExtra={image as VenueExtra} />
                </div>
                {image.value ? (
                  <img key={image.name} src={image.value} alt={image.name} className="w-full h-48  object-cover" />
                ) : (
                  <div className="w-full  object-cover h-48  bg-accent flex items-center justify-center">
                    <Image className="h-12 w-12 m-auto text-muted-foreground" />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 h-full md:h-96 overflow-hidden  rounded-lg">
      <div className="h-96">
        <img src={mainImage?.value} alt="Main image" className="w-full h-96 object-cover" />
      </div>
      <div className="grid grid-cols-2 gap-2">
        {images
          .filter(image => image.name !== 'main')
          .splice(0, 4)
          .map(image =>
            image.value ? (
              <img key={image.venueExtraId} src={image.value} alt={image.name} className="w-full h-24 md:h-48 object-cover" />
            ) : (
              <div className="w-full h-48 object-cover bg-accent flex items-center justify-center" />
            )
          )}
      </div>
    </div>
  );
}
