import { Link, useLocation } from 'react-router-dom';
import { LogOut, MenuIcon, Search, User2 } from 'lucide-react';
import { cn } from '@/utils';
import { buttonVariants } from '@/components/ui/button';
import storage from '@/utils/storage';
import { Avatar, AvatarFallback } from '../ui/avatar';
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger, DropdownMenu } from '@/components/ui/dropdown-menu';
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '../ui/menubar';
import { logout } from '@/features/auth/api/logout';
import { User } from '@/features/user/types';

const routes = [
  {
    title: 'Search',
    path: 'venues/search',
    icon: Search,
    variant: 'ghost',
    role: null,
  },

  {
    title: 'Venues',
    path: 'venues',
    icon: Search,
    variant: 'ghost',
    role: 'admin',
  },
  {
    title: 'Locations',
    path: 'locations',
    icon: Search,
    variant: 'ghost',
    role: 'superadmin',
  },
  // {
  //   title: 'Services',
  //   path: 'services',
  //   icon: Search,
  //   variant: 'ghost',
  //   role: 'admin',
  // },
  {
    title: 'Users',
    path: 'users',
    icon: Search,
    variant: 'ghost',
    role: 'admin',
  },
] as const;

export function Menu() {
  const { pathname } = useLocation();

  const user = storage.getUser();

  const filteredRoutes = routes.filter(({ role }) => {
    if (role) {
      if (role === 'superadmin' && user.admin && !user.venueGroupId) {
        return true;
      } else if (role === 'admin' && user.admin) {
        return true;
      }
      return false;
    }
    return true;
  });

  return (
    <Menubar className="border-0">
      <MenubarMenu>
        <MenubarTrigger className="block lg:hidden">
          <MenuIcon className="h-6 w-6" />
        </MenubarTrigger>
        <MenubarContent>
          {filteredRoutes.map((link, index) => (
            <MenubarItem className="w-full">
              <Link key={index} to={link.path} className={cn(buttonVariants({ variant: link.variant, size: 'sm' }), 'rounded-none', 'justify-start relative p-0')}>
                {(`/${link.path}` === pathname || (pathname === '/' && link.path === 'venues/search')) && (
                  <span className="absolute  -left-3 w-[3px] rounded-sm h-4 bg-brand"></span>
                )}
                {link.title}
              </Link>
            </MenubarItem>
          ))}
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <div className=" items-center justify-between w-full lg:flex hidden">
          <div className="flex items-center">
            {filteredRoutes.map((link, index) => (
              <MenubarTrigger className="bg-[transparent_!important] p-0 relative">
                <Link key={index} to={link.path} className={cn(buttonVariants({ variant: link.variant, size: 'sm' }), 'rounded-none', 'justify-start relative')}>
                  {(`/${link.path}` === pathname || (pathname === '/' && link.path === 'venues/search')) && (
                    <span className="absolute -bottom-[3px] left-0 h-[3px] rounded-sm w-full bg-brand"></span>
                  )}
                  {link.title}
                </Link>
              </MenubarTrigger>
            ))}
          </div>
          <div className="flex items-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center h-full">
                <Avatar className="flex items-center">
                  <AvatarFallback className="bg-brand/80 text-white size-8 text-sm font-semibold">{user?.userName.slice(0, 2).toUpperCase()}</AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>
                  <Link className="flex items-center" to={`/users/edit/${user.userId}`}>
                    <User2 className="mr-2 h-4 w-4" />
                    My profile
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={logout}>
                  <Link className="flex items-center" to={`/users/edit/${user.userId}`}>
                    <LogOut className="mr-2 h-4 w-4" />
                    Logout
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </MenubarMenu>
    </Menubar>
  );
}
