import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '../types';

export const getUsers = (venueGroupId?: number | null): Promise<User[]> => {
  const searchParams = venueGroupId ? `venueGroupId=${venueGroupId}` : '';
  return axios.get(`/user/venueGroup?${searchParams}`);
};

type QueryFnType = typeof getUsers;

type UserUsersOption = {
  venueGroupId?: number | null;
  config?: QueryConfig<QueryFnType>;
};

export const useUsersByVenuGroup = ({ config, venueGroupId }: UserUsersOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['users', venueGroupId],
    queryFn: () => getUsers(venueGroupId),
    ...config,
  });
};
