import { GOOGLE_MAPS_API_KEY } from '@/config';
import { useEffect, useState } from 'react';
import { GoogleMap as GoogleMapApi, useJsApiLoader, Marker } from '@react-google-maps/api';
import axios from 'axios';

interface GoogleMapProps {
  address: string;
}

export function GoogleMap({ address }: GoogleMapProps) {
  const [location, setLocation] = useState<any>(null);
  const [formattedAddress, setFormattedAddress] = useState<string>('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    async function fetchLocation() {
      const encodedAddress = encodeURIComponent(address.replace(/[åä]/g, 'a').replace(/ö/g, 'o'));
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${GOOGLE_MAPS_API_KEY}`);

      const location = response?.data?.results?.[0]?.geometry?.location;
      const formattedAddress = response?.data?.results?.[0]?.formatted_address;
      setLocation(location);
      setFormattedAddress(formattedAddress);
    }
    fetchLocation();
  }, [address]);

  if ((!location && isLoaded) || !address) return <div className="w-full flex items-center justify-center h-full text-lg bg-accent">No address set</div>;
  if (!location || !isLoaded) return <div>Loading...</div>;

  return (
    <div className="w-full h-96">
      <GoogleMapApi
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        center={location}
        zoom={15}
      >
        <Marker position={location} title={formattedAddress} />
      </GoogleMapApi>
    </div>
  );
}
