import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Location } from '../types';

export const getLocations = (): Promise<Location[]> => {
  return axios.get(`/Location/GetAllLocations`);
};

type QueryFnType = typeof getLocations;

type UseLocationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLocations = ({ config }: UseLocationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['locations'],
    queryFn: () => getLocations(),
    ...config,
  });
};
