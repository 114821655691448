import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { BadgeCheck } from 'lucide-react';
import { useState } from 'react';
import { forgotPassword } from '../api/forgotPassword';
import { useToast } from '@/components/ui/use-toast';
import { Venue4YouLogo } from '@/components/Venue4YouLogo';
import { Card } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { useLocationStore } from '@/stores/locationStore';

const formSchema = z.object({
  username: z.string().email({ message: 'Ange en giltig e-postadress' }),
});

export function ForgotPassword() {
  const [hasSent, setHasSent] = useState(false);

  const parentLocation = useLocationStore(state => state.parentLocation);

  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      await forgotPassword({ username: values.username, baseURL: parentLocation !== '' ? window.location.origin : parentLocation });
      setHasSent(true);
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Något gick fel!',
      });
    }
  }

  if (hasSent) {
    return (
      <div className="bg-zinc-100 h-screen w-full flex flex-col items-center justify-center">
        {window.location === window.parent.location && (
          <div className="w-80 mx-auto mb-4">
            <Venue4YouLogo />
          </div>
        )}
        <div className="space-y-4 text-center">
          <div className="space-y-2  flex flex-col items-center">
            <BadgeCheck size={48} />
            <h3 className="text-lg font-medium">A reset link has been sent!</h3>
            <p>
              We have sent an email to <strong className="underline">{form.watch('username')} </strong> with instructions to reset your password.
            </p>
          </div>
          <div>
            <Link to="/login">
              <Button>Login</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-zinc-100 h-screen w-full flex flex-col items-center justify-center">
      {window.location === window.parent.location && (
        <div className="w-80 mx-auto mb-4">
          <Venue4YouLogo />
        </div>
      )}
      <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
        <div className="space-y-2">
          <h3 className="text-lg font-medium">Restore password</h3>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="example@gmail.com" type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Send</Button>
            </form>
          </Form>
        </div>
      </Card>
    </div>
  );
}
