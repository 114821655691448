import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { VenueExtra } from '../types';

export type UpsertVenueExtraDto = {
  venueExtraId?: number;
  venueId: number;
  type: 'image' | 'link';
  name: string;
  value?: string;
};

export const upsertVenueExtra = (data: UpsertVenueExtraDto): Promise<VenueExtra> => {
  return axios.post(`/venueExtra`, {
    ...data,
    venueExtraId: data.venueExtraId ?? 0,
    value: data.value ?? '',
  });
};

type UseUpdateVenueDTO = {
  config?: MutationConfig<typeof upsertVenueExtra>;
};

export const useUpsertVenueExtra = ({ config }: UseUpdateVenueDTO) => {
  // const { toast } = useToast();

  return useMutation({
    // onError: (_, __, ___: any) => {
    //   toast({
    //     variant: 'destructive',
    //     title: 'Something went wrong!',
    //   });
    // },

    ...config,
    mutationFn: (data: any) =>
      upsertVenueExtra({
        ...data,
      }),
  });
};
