import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeft, User } from 'lucide-react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useUser } from '../api/getUser';
import { useUpdateUser } from '../api/updateUser';
import { useNavigate, useParams } from 'react-router-dom';

const formSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  title: z.string().optional(),
  organization: z.string().optional(),
});

export function UserEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser({
    userId: Number(id),
  });
  const { mutateAsync: updateUser } = useUpdateUser({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      title: user?.title ?? '',
      organization: user?.organization ?? '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    await updateUser({ ...user, ...values } as any);
  }

  return (
    <div className="flex space-x-4 py-6 pt-0 w-full h-full ">
      <div className="flex flex-col w-full ">
        <div className="flex w-full items-center bg-card px-6 h-fit justify-between  border-b border-b-input">
          <div className="max-w-6xl px-4  mx-auto py-4 flex items-center justify-between w-full">
            <div className="flex gap-x-1 items-center">
              <Button variant="ghost" onClick={() => navigate(-1)} className="flex items-center p-0">
                <ArrowLeft className="h-4 w-4 mr-2" />
              </Button>
              <User className="h-6 w-6 mr-1" />
              <h3 className="text-lg font-medium">{`
                ${user?.firstName} ${user?.lastName}
              `}</h3>
            </div>
          </div>
        </div>

        <div className="max-w-6xl px-4  mx-auto py-4 flex items-center justify-between w-full">
          <Card className="space-y-2 p-6 ml-0 w-96 my-6">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-sm">
                      <FormLabel>First name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-sm">
                      <FormLabel>Last name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-sm">
                      <FormLabel>Title</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="organization"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-sm">
                      <FormLabel>Organization</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit">Update</Button>
              </form>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}
