import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Filters } from '../types';

interface ConferenceFiltersProps {
  filters: Filters;
  onChange: (key: string, value: string) => void;
  disabled?: boolean;
}

const conferenceFilters = [
  {
    title: 'Number of conference rooms',
    value: 'conferenceRooms',
    options: [
      { value: 'empty', label: 'Select option...' },
      { value: '1TO5', label: '1-5' },
      { value: '5TO10', label: '5-10' },
      { value: '10TO15', label: '10-15' },
      { value: 'GREATEROREQUAL15', label: '15 or more' },
    ],
  },
  {
    title: 'Largest conference rooms, number of seats',
    value: 'conferenceLargestRoom',
    options: [
      { value: 'empty', label: 'Select option...' },

      { value: '1TO100', label: 'Up to 100 seats' },
      { value: '100TO300', label: '100-300 seats' },
      { value: '300TO500', label: '300-500 seats' },
      { value: '500TO1000', label: '500-1000 seats' },
      { value: 'GREATEROREQUAL1000', label: '1000 or more seats' },
    ],
  },
  {
    title: 'Number of deligates',
    value: 'conferenceMaxDelegates',
    options: [
      { value: 'empty', label: 'Select option...' },

      { value: '1TO100', label: 'Up to 100' },
      { value: '100TO500', label: '100-500' },
      { value: '500TO1000', label: '500-1000' },
      { value: '1000TO2000', label: '1000-2,000' },
      { value: '2000TO5000', label: '2,000-5,000' },
      { value: '5000TO10000', label: '5,000-10,000' },
      { value: 'GREATEROREQUAL10000', label: '10,000 or more' },
    ],
  },
];

export function ConferenceFilters({ filters, onChange }: ConferenceFiltersProps) {
  return (
    <div className="space-y-2">
      <div>
        {conferenceFilters.map(filter => (
          <div key={filter.value} className="flex flex-col items-start space-y-2 mb-2">
            <label className="font-semibold text-sm">{filter.title}</label>
            <Select value={(filters?.[filter.value as keyof typeof filters] as string) ?? 'empty'} onValueChange={value => onChange(filter.value, value)}>
              <SelectTrigger >
                <SelectValue placeholder="Select a fruit" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {filter.options.map(option => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        ))}
      </div>
    </div>
  );
}
