import { useState } from 'react';
import { Building2, Plus, Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import storage from '@/utils/storage';
import { EditVenueCard } from '../components/EditVenueCard';
import { useDeleteVenue } from '../api/deleteVenue';
import { useVenueGroups } from '../api/getVenuesByVenueGroup';
import { useVenues } from '../api/getVenues';

export function Venues() {
  const [searchTerm, setSearchTerm] = useState('');

  const { venueGroupId, admin } = storage.getUser();

  const { data: venues, isFetched } =
    admin && venueGroupId === null
      ? useVenues({})
      : useVenueGroups({
          venueGroupId: venueGroupId,
        });

  const { mutateAsync: deleteVenue } = useDeleteVenue({});

  const filteredVenues = venues
    ?.filter(venue => venue.venueName?.toLowerCase().includes(searchTerm?.toLowerCase()))
    ?.sort((a, b) => {
      if (a.awaitingReview && !b.awaitingReview) {
        return -1;
      } else if (!a.awaitingReview && b.awaitingReview) {
        return 1;
      } else {
        return a.venueName.localeCompare(b.venueName);
      }
    });

  return (
    <div className="flex space-x-4 py-6 pt-0 w-full h-full ">
      <div className="flex flex-col w-full ">
        <div className="flex w-full items-center bg-card px-6 h-fit justify-between fixed z-20  border-b border-b-input">
          <div className="max-w-6xl px-4  mx-auto py-4 flex items-center justify-between w-full">
            <div className="flex gap-x-1 items-center">
              <Building2 className="h-6 w-6 mr-1" />
              <h3 className="text-lg font-medium">Venues</h3>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="w-full">
                <div className="relative w-full ">
                  <Search className="absolute h-4 w-4 left-2 top-1/2 -translate-y-1/2" />
                  <Input onChange={event => setSearchTerm(event.target.value)} placeholder="Search for venue name" className="pl-8" />
                </div>
              </div>
              <Link to={`/venues/create`} className="flex items-center justify-center">
                <Button variant="default" size="sm">
                  <Plus className="h-4 w-4 mr-1" />
                  Add
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="space-y-2 p-6 pt-0  mt-24">
          {venues?.length === 0 && isFetched && (
            <div className="flex w-full h-full items-center justify-center">
              <span className="text-lg text-muted-foreground">No venues created yet</span>
            </div>
          )}
          <div className="grid md:grid-cols-2 2xl:grid-cols-4 lg:grid-cols-3 gap-4 grid-cols-1 max-w-6xl mx-auto py-6 ">
            {filteredVenues?.splice(0, 50)?.map(venue => (
              <EditVenueCard key={venue.venueId} venue={venue} onDelete={deleteVenue} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
