import { User } from "@/features/user/types";

const storagePrefix = 'venue4you-';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setUser: (userName: string) => {
    window.localStorage.setItem(`${storagePrefix}user`, JSON.stringify(userName));
  },
  getUser: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}user`) as string) as User;
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}user`);
  },
  getLastestUrl: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}lastestUrl`) as string);
  },
  setRedirectUrl: (url: string) => {
    window.localStorage.setItem(`${storagePrefix}redirect-url`, JSON.stringify(url));
  },
  clearRedirectUrl: () => {
    window.localStorage.removeItem(`${storagePrefix}redirect-url`);
  },
  getRedirectUrl: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}redirect-url`) as string);
  },
  getClientId: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}clientId`) as string);
  },
};

export default storage;
