import { AppRoutes } from './routes';
import { AppProvider } from './providers/app';
import { Toaster } from '@/components/ui/toaster';
import * as Sentry from '@sentry/react';
import { TooltipProvider } from './components/ui/tooltip';
import { useEffect } from 'react';
import { useHeightObserver } from './hooks/useHeightObserver';
import { useSearchQueryStore } from './stores/searchQueryStore';
import { useLocationStore } from './stores/locationStore';

function App() {
  const body = { current: document.body };

  const location = useLocationStore(state => state.location);
  const searchQuery = useSearchQueryStore(state => state.searchQuery);
  const setParentLocation = useLocationStore(state => state.setParentLocation);

  useEffect(() => {
    if (window.location !== window.parent.location) {
      window.addEventListener('message', event => {
        console.log('event.data', event.data?.embedHost);
        if (event.data?.embedHost && event.data?.embedPath) {
          // const searchParams = new URLSearchParams(event.data.embedSearch);
          // if (searchParams.has('append')) {
          //   const appendedPath = searchParams.get('append');
          //   const newPath = `${event.data.embedPath}/${appendedPath}`;
          //   window.location.pathname = newPath;
          // }
          setParentLocation(`${event.data.embedHost}${event.data.embedPath}`);
        }
      });
      parent.postMessage({ reqOverviewScroll: true }, '*');
      parent.postMessage({ height: 1000 }, '*');
      parent.postMessage({ origin: true }, '*');
    }
  }, []);

  useEffect(() => {
    if (searchQuery !== '' && location === '/venues/search') {
      const searchGridScrollHeight = document.getElementById('search-grid')?.clientHeight;
      const requestHeight = searchGridScrollHeight ? searchGridScrollHeight + 350 : 500;
      parent.postMessage({ height: requestHeight }, '*');
    } else {
      parent.postMessage({ height: 500 }, '*');
    }
  }, [location, searchQuery]);

  useHeightObserver(body, body, (height: number) => parent.postMessage({ height: height }, '*'), location);

  return (
    <>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <AppProvider>
          <TooltipProvider>
            <Toaster />
            <AppRoutes />
          </TooltipProvider>
        </AppProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default App;
