export function Venue4YouLogo({ mono }: { mono?: boolean }) {
  if (mono) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
      zoomAndPan="magnify"
      viewBox="0 0 375 149.999998"
      height="100%"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
      >
        <defs>
          <g />
        </defs>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(17.111276, 84.238228)">
            <g>
              <path d="M 12.921875 0 L 23.410156 0 L 35.335938 -31.714844 L 27.71875 -31.714844 L 18.417969 -5.929688 L 17.917969 -5.929688 L 8.617188 -31.714844 L 1 -31.714844 Z M 12.921875 0 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(53.443047, 84.238228)">
            <g>
              <path d="M 19.726562 1 C 27.34375 1 33.960938 -3.121094 35.710938 -9.238281 L 29.46875 -10.238281 C 28.15625 -6.679688 24.347656 -4.121094 20.039062 -4.121094 C 14.171875 -4.121094 9.988281 -8.054688 9.488281 -13.921875 L 36.707031 -13.921875 L 36.769531 -15.855469 C 36.769531 -25.910156 29.902344 -32.714844 19.789062 -32.714844 C 9.488281 -32.714844 2.246094 -25.972656 2.246094 -15.917969 C 2.246094 -5.804688 9.425781 1 19.726562 1 Z M 9.425781 -18.042969 C 9.988281 -23.722656 13.984375 -27.65625 19.664062 -27.65625 C 25.160156 -27.65625 29.21875 -23.535156 29.59375 -18.042969 Z M 9.425781 -18.042969 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(92.334277, 84.238228)">
            <g>
              <path d="M 4.121094 0 L 11.175781 0 L 11.175781 -18.230469 C 11.175781 -23.160156 14.796875 -26.65625 19.414062 -26.65625 C 24.097656 -26.65625 26.96875 -23.035156 26.96875 -17.917969 L 26.96875 0 L 34.085938 0 L 34.085938 -19.164062 C 34.085938 -26.96875 29.28125 -32.714844 22.039062 -32.714844 C 17.480469 -32.714844 13.921875 -30.402344 11.675781 -26.28125 L 11.175781 -26.28125 L 11.175781 -31.714844 L 4.121094 -31.714844 Z M 4.121094 0 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(129.976989, 84.238228)">
            <g>
              <path d="M 15.609375 1 C 20.164062 1 23.722656 -1.3125 25.972656 -5.429688 L 26.46875 -5.429688 L 26.46875 0 L 33.585938 0 L 33.585938 -31.714844 L 26.46875 -31.714844 L 26.46875 -13.546875 C 26.46875 -8.617188 22.847656 -5.058594 18.292969 -5.058594 C 13.609375 -5.058594 10.675781 -8.679688 10.675781 -13.796875 L 10.675781 -31.714844 L 3.558594 -31.714844 L 3.558594 -12.609375 C 3.558594 -4.746094 8.367188 1 15.609375 1 Z M 15.609375 1 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(167.619706, 84.238228)">
            <g>
              <path d="M 19.726562 1 C 27.34375 1 33.960938 -3.121094 35.710938 -9.238281 L 29.46875 -10.238281 C 28.15625 -6.679688 24.347656 -4.121094 20.039062 -4.121094 C 14.171875 -4.121094 9.988281 -8.054688 9.488281 -13.921875 L 36.707031 -13.921875 L 36.769531 -15.855469 C 36.769531 -25.910156 29.902344 -32.714844 19.789062 -32.714844 C 9.488281 -32.714844 2.246094 -25.972656 2.246094 -15.917969 C 2.246094 -5.804688 9.425781 1 19.726562 1 Z M 9.425781 -18.042969 C 9.988281 -23.722656 13.984375 -27.65625 19.664062 -27.65625 C 25.160156 -27.65625 29.21875 -23.535156 29.59375 -18.042969 Z M 9.425781 -18.042969 " />
            </g>
          </g>
        </g>
        <g fill="#f85c3a" fillOpacity="1">
          <g transform="translate(206.509702, 84.238228)">
            <g>
              <path d="M 22.910156 0 L 30.777344 0 L 30.777344 -10.363281 L 36.085938 -10.363281 L 36.085938 -16.230469 L 30.777344 -16.230469 L 30.777344 -44.199219 L 19.351562 -44.199219 L 1.5 -14.609375 L 1.5 -10.363281 L 22.910156 -10.363281 Z M 8.988281 -16.230469 L 22.414062 -38.207031 L 22.910156 -38.207031 L 22.910156 -16.230469 Z M 8.988281 -16.230469 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(245.017513, 84.238228)">
            <g>
              <path d="M 9.675781 14.234375 C 15.792969 14.234375 19.289062 9.550781 22.660156 0.8125 L 35.335938 -31.714844 L 28.03125 -31.714844 L 19.539062 -9.050781 L 18.980469 -9.050781 L 8.738281 -31.714844 L 1.0625 -31.714844 L 16.480469 0.125 C 14.484375 4.933594 12.359375 8.429688 8.429688 8.429688 C 7.054688 8.429688 5.242188 8.117188 3.933594 7.679688 L 3.933594 13.359375 C 5.367188 13.859375 7.804688 14.234375 9.675781 14.234375 Z M 9.675781 14.234375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(281.41171, 84.238228)">
            <g>
              <path d="M 19.414062 1 C 29.714844 1 36.707031 -5.679688 36.707031 -15.855469 C 36.707031 -26.035156 29.714844 -32.714844 19.414062 -32.714844 C 9.113281 -32.714844 2.183594 -26.097656 2.183594 -15.855469 C 2.183594 -5.679688 9.175781 1 19.414062 1 Z M 19.414062 -5.058594 C 13.484375 -5.058594 9.238281 -9.550781 9.238281 -15.855469 C 9.238281 -22.164062 13.484375 -26.65625 19.414062 -26.65625 C 25.410156 -26.65625 29.652344 -22.164062 29.652344 -15.855469 C 29.652344 -9.550781 25.410156 -5.058594 19.414062 -5.058594 Z M 19.414062 -5.058594 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(320.178085, 84.238228)">
            <g>
              <path d="M 15.609375 1 C 20.164062 1 23.722656 -1.3125 25.972656 -5.429688 L 26.46875 -5.429688 L 26.46875 0 L 33.585938 0 L 33.585938 -31.714844 L 26.46875 -31.714844 L 26.46875 -13.546875 C 26.46875 -8.617188 22.847656 -5.058594 18.292969 -5.058594 C 13.609375 -5.058594 10.675781 -8.679688 10.675781 -13.796875 L 10.675781 -31.714844 L 3.558594 -31.714844 L 3.558594 -12.609375 C 3.558594 -4.746094 8.367188 1 15.609375 1 Z M 15.609375 1 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(110.686404, 117.426646)">
            <g>
              <path d="M 3.40625 -2.09375 L 3.40625 0 L 2.359375 0 L 2.359375 -2.0625 L 0.015625 -6.296875 L 1.078125 -6.296875 L 2.875 -3.234375 L 4.640625 -6.296875 L 5.71875 -6.296875 Z M 3.40625 -2.09375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(117.166989, 117.426646)">
            <g>
              <path d="M 1.265625 -5.40625 C 1.910156 -6.019531 2.703125 -6.328125 3.640625 -6.328125 C 4.585938 -6.328125 5.378906 -6.019531 6.015625 -5.40625 C 6.660156 -4.800781 6.984375 -4.046875 6.984375 -3.140625 C 6.984375 -2.242188 6.660156 -1.488281 6.015625 -0.875 C 5.378906 -0.257812 4.585938 0.046875 3.640625 0.046875 C 2.703125 0.046875 1.910156 -0.257812 1.265625 -0.875 C 0.628906 -1.488281 0.3125 -2.242188 0.3125 -3.140625 C 0.3125 -4.046875 0.628906 -4.800781 1.265625 -5.40625 Z M 3.65625 -5.375 C 3.03125 -5.375 2.492188 -5.160156 2.046875 -4.734375 C 1.609375 -4.304688 1.390625 -3.78125 1.390625 -3.15625 C 1.390625 -2.53125 1.613281 -2 2.0625 -1.5625 C 2.507812 -1.125 3.039062 -0.90625 3.65625 -0.90625 C 4.269531 -0.90625 4.796875 -1.125 5.234375 -1.5625 C 5.671875 -2 5.890625 -2.53125 5.890625 -3.15625 C 5.890625 -3.78125 5.671875 -4.304688 5.234375 -4.734375 C 4.796875 -5.160156 4.269531 -5.375 3.65625 -5.375 Z M 3.65625 -5.375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(125.204577, 117.426646)">
            <g>
              <path d="M 6.1875 -6.296875 L 6.1875 -2.609375 C 6.1875 -1.785156 5.941406 -1.132812 5.453125 -0.65625 C 4.960938 -0.1875 4.289062 0.046875 3.4375 0.046875 C 2.59375 0.046875 1.925781 -0.1875 1.4375 -0.65625 C 0.945312 -1.132812 0.703125 -1.785156 0.703125 -2.609375 L 0.703125 -6.296875 L 1.765625 -6.296875 L 1.765625 -2.609375 C 1.765625 -2.078125 1.914062 -1.660156 2.21875 -1.359375 C 2.519531 -1.054688 2.929688 -0.90625 3.453125 -0.90625 C 3.972656 -0.90625 4.378906 -1.054688 4.671875 -1.359375 C 4.972656 -1.660156 5.125 -2.078125 5.125 -2.609375 L 5.125 -6.296875 Z M 6.1875 -6.296875 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(132.828162, 117.426646)">
            <g>
              <path d="M 4.828125 0 L 3.671875 -1.90625 C 3.609375 -1.90625 3.515625 -1.90625 3.390625 -1.90625 L 1.890625 -1.90625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 3.390625 -6.296875 C 4.191406 -6.296875 4.8125 -6.109375 5.25 -5.734375 C 5.695312 -5.359375 5.921875 -4.832031 5.921875 -4.15625 C 5.921875 -3.644531 5.804688 -3.210938 5.578125 -2.859375 C 5.359375 -2.515625 5.035156 -2.265625 4.609375 -2.109375 L 6.03125 0 Z M 1.890625 -2.859375 L 3.390625 -2.859375 C 4.398438 -2.859375 4.90625 -3.28125 4.90625 -4.125 C 4.90625 -4.9375 4.398438 -5.34375 3.390625 -5.34375 L 1.890625 -5.34375 Z M 1.890625 -2.859375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(140.082746, 117.426646)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(143.152306, 117.426646)">
            <g>
              <path d="M 5.140625 -3.09375 L 6.0625 -3.09375 L 6.0625 -0.78125 C 5.738281 -0.53125 5.351562 -0.328125 4.90625 -0.171875 C 4.46875 -0.0234375 4.039062 0.046875 3.625 0.046875 C 2.6875 0.046875 1.898438 -0.257812 1.265625 -0.875 C 0.628906 -1.488281 0.3125 -2.242188 0.3125 -3.140625 C 0.3125 -4.046875 0.632812 -4.800781 1.28125 -5.40625 C 1.925781 -6.019531 2.726562 -6.328125 3.6875 -6.328125 C 4.125 -6.328125 4.554688 -6.242188 4.984375 -6.078125 C 5.421875 -5.921875 5.796875 -5.707031 6.109375 -5.4375 L 5.5 -4.65625 C 4.976562 -5.132812 4.375 -5.375 3.6875 -5.375 C 3.039062 -5.375 2.492188 -5.160156 2.046875 -4.734375 C 1.609375 -4.304688 1.390625 -3.78125 1.390625 -3.15625 C 1.390625 -2.519531 1.613281 -1.984375 2.0625 -1.546875 C 2.507812 -1.117188 3.050781 -0.90625 3.6875 -0.90625 C 4.175781 -0.90625 4.660156 -1.050781 5.140625 -1.34375 Z M 5.140625 -3.09375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(150.640899, 117.426646)">
            <g>
              <path d="M 1.890625 -0.96875 L 4.609375 -0.96875 L 4.609375 0 L 0.828125 0 L 0.828125 -6.296875 L 1.890625 -6.296875 Z M 1.890625 -0.96875 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(156.194479, 117.426646)">
            <g>
              <path d="M 1.265625 -5.40625 C 1.910156 -6.019531 2.703125 -6.328125 3.640625 -6.328125 C 4.585938 -6.328125 5.378906 -6.019531 6.015625 -5.40625 C 6.660156 -4.800781 6.984375 -4.046875 6.984375 -3.140625 C 6.984375 -2.242188 6.660156 -1.488281 6.015625 -0.875 C 5.378906 -0.257812 4.585938 0.046875 3.640625 0.046875 C 2.703125 0.046875 1.910156 -0.257812 1.265625 -0.875 C 0.628906 -1.488281 0.3125 -2.242188 0.3125 -3.140625 C 0.3125 -4.046875 0.628906 -4.800781 1.265625 -5.40625 Z M 3.65625 -5.375 C 3.03125 -5.375 2.492188 -5.160156 2.046875 -4.734375 C 1.609375 -4.304688 1.390625 -3.78125 1.390625 -3.15625 C 1.390625 -2.53125 1.613281 -2 2.0625 -1.5625 C 2.507812 -1.125 3.039062 -0.90625 3.65625 -0.90625 C 4.269531 -0.90625 4.796875 -1.125 5.234375 -1.5625 C 5.671875 -2 5.890625 -2.53125 5.890625 -3.15625 C 5.890625 -3.78125 5.671875 -4.304688 5.234375 -4.734375 C 4.796875 -5.160156 4.269531 -5.375 3.65625 -5.375 Z M 3.65625 -5.375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(164.232064, 117.426646)">
            <g>
              <path d="M 0.828125 -6.296875 L 3.5625 -6.296875 C 4.21875 -6.296875 4.734375 -6.15625 5.109375 -5.875 C 5.484375 -5.601562 5.671875 -5.21875 5.671875 -4.71875 C 5.671875 -4.363281 5.570312 -4.0625 5.375 -3.8125 C 5.175781 -3.570312 4.910156 -3.410156 4.578125 -3.328125 C 4.992188 -3.253906 5.320312 -3.070312 5.5625 -2.78125 C 5.800781 -2.5 5.921875 -2.148438 5.921875 -1.734375 C 5.921875 -1.191406 5.71875 -0.765625 5.3125 -0.453125 C 4.90625 -0.148438 4.34375 0 3.625 0 L 0.828125 0 Z M 1.890625 -5.359375 L 1.890625 -3.6875 L 3.515625 -3.6875 C 3.847656 -3.6875 4.109375 -3.757812 4.296875 -3.90625 C 4.492188 -4.0625 4.59375 -4.269531 4.59375 -4.53125 C 4.59375 -4.800781 4.492188 -5.003906 4.296875 -5.140625 C 4.109375 -5.285156 3.847656 -5.359375 3.515625 -5.359375 Z M 1.890625 -2.75 L 1.890625 -0.9375 L 3.515625 -0.9375 C 3.929688 -0.9375 4.253906 -1.015625 4.484375 -1.171875 C 4.710938 -1.335938 4.828125 -1.566406 4.828125 -1.859375 C 4.828125 -2.140625 4.707031 -2.359375 4.46875 -2.515625 C 4.238281 -2.671875 3.921875 -2.75 3.515625 -2.75 Z M 1.890625 -2.75 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(171.333649, 117.426646)">
            <g>
              <path d="M 4.890625 -1.421875 L 1.71875 -1.421875 L 1.109375 0 L 0.015625 0 L 2.796875 -6.296875 L 3.875 -6.296875 L 6.640625 0 L 5.5 0 Z M 4.484375 -2.359375 L 3.296875 -5.171875 L 2.109375 -2.359375 Z M 4.484375 -2.359375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(178.723236, 117.426646)">
            <g>
              <path d="M 1.890625 -0.96875 L 4.609375 -0.96875 L 4.609375 0 L 0.828125 0 L 0.828125 -6.296875 L 1.890625 -6.296875 Z M 1.890625 -0.96875 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(184.276814, 117.426646)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(187.346371, 117.426646)">
            <g>
              <path d="M 3.640625 0 L 2.53125 0 L 0.03125 -6.296875 L 1.171875 -6.296875 L 3.109375 -1.171875 L 5.078125 -6.296875 L 6.1875 -6.296875 Z M 3.640625 0 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(194.303955, 117.426646)">
            <g>
              <path d="M 5.328125 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.640625 L 4.96875 -3.640625 L 4.96875 -2.6875 L 1.890625 -2.6875 L 1.890625 -0.953125 L 5.4375 -0.953125 L 5.4375 0 L 0.828125 0 L 0.828125 -6.296875 L 5.328125 -6.296875 Z M 5.328125 -5.34375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(200.955535, 117.426646)">
            <g>
              <path d="M 6.296875 0 L 5.25 0 L 1.890625 -4.515625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 1.875 -6.296875 L 5.25 -1.765625 L 5.25 -6.296875 L 6.296875 -6.296875 Z M 6.296875 0 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(208.831135, 117.426646)">
            <g>
              <path d="M 6.1875 -6.296875 L 6.1875 -2.609375 C 6.1875 -1.785156 5.941406 -1.132812 5.453125 -0.65625 C 4.960938 -0.1875 4.289062 0.046875 3.4375 0.046875 C 2.59375 0.046875 1.925781 -0.1875 1.4375 -0.65625 C 0.945312 -1.132812 0.703125 -1.785156 0.703125 -2.609375 L 0.703125 -6.296875 L 1.765625 -6.296875 L 1.765625 -2.609375 C 1.765625 -2.078125 1.914062 -1.660156 2.21875 -1.359375 C 2.519531 -1.054688 2.929688 -0.90625 3.453125 -0.90625 C 3.972656 -0.90625 4.378906 -1.054688 4.671875 -1.359375 C 4.972656 -1.660156 5.125 -2.078125 5.125 -2.609375 L 5.125 -6.296875 Z M 6.1875 -6.296875 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(216.45472, 117.426646)">
            <g>
              <path d="M 5.328125 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.640625 L 4.96875 -3.640625 L 4.96875 -2.6875 L 1.890625 -2.6875 L 1.890625 -0.953125 L 5.4375 -0.953125 L 5.4375 0 L 0.828125 0 L 0.828125 -6.296875 L 5.328125 -6.296875 Z M 5.328125 -5.34375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(223.1063, 117.426646)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(226.175857, 117.426646)">
            <g>
              <path d="M 5.046875 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.53125 L 4.75 -3.53125 L 4.75 -2.578125 L 1.890625 -2.578125 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 5.0625 -6.296875 Z M 5.046875 -5.34375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(232.098437, 117.426646)">
            <g>
              <path d="M 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 1.890625 -6.296875 Z M 1.890625 0 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(235.563994, 117.426646)">
            <g>
              <path d="M 6.296875 0 L 5.25 0 L 1.890625 -4.515625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 1.875 -6.296875 L 5.25 -1.765625 L 5.25 -6.296875 L 6.296875 -6.296875 Z M 6.296875 0 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(243.439589, 117.426646)">
            <g>
              <path d="M 0.828125 -6.296875 L 3.40625 -6.296875 C 4.351562 -6.296875 5.132812 -6 5.75 -5.40625 C 6.363281 -4.8125 6.671875 -4.054688 6.671875 -3.140625 C 6.671875 -2.234375 6.359375 -1.484375 5.734375 -0.890625 C 5.117188 -0.296875 4.335938 0 3.390625 0 L 0.828125 0 Z M 1.890625 -5.34375 L 1.890625 -0.953125 L 3.4375 -0.953125 C 4.050781 -0.953125 4.5625 -1.15625 4.96875 -1.5625 C 5.382812 -1.976562 5.59375 -2.503906 5.59375 -3.140625 C 5.59375 -3.773438 5.378906 -4.300781 4.953125 -4.71875 C 4.535156 -5.132812 4.015625 -5.34375 3.390625 -5.34375 Z M 1.890625 -5.34375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(251.162176, 117.426646)">
            <g>
              <path d="M 5.328125 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.640625 L 4.96875 -3.640625 L 4.96875 -2.6875 L 1.890625 -2.6875 L 1.890625 -0.953125 L 5.4375 -0.953125 L 5.4375 0 L 0.828125 0 L 0.828125 -6.296875 L 5.328125 -6.296875 Z M 5.328125 -5.34375 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(257.813756, 117.426646)">
            <g>
              <path d="M 4.828125 0 L 3.671875 -1.90625 C 3.609375 -1.90625 3.515625 -1.90625 3.390625 -1.90625 L 1.890625 -1.90625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 3.390625 -6.296875 C 4.191406 -6.296875 4.8125 -6.109375 5.25 -5.734375 C 5.695312 -5.359375 5.921875 -4.832031 5.921875 -4.15625 C 5.921875 -3.644531 5.804688 -3.210938 5.578125 -2.859375 C 5.359375 -2.515625 5.035156 -2.265625 4.609375 -2.109375 L 6.03125 0 Z M 1.890625 -2.859375 L 3.390625 -2.859375 C 4.398438 -2.859375 4.90625 -3.28125 4.90625 -4.125 C 4.90625 -4.9375 4.398438 -5.34375 3.390625 -5.34375 L 1.890625 -5.34375 Z M 1.890625 -2.859375 " />
            </g>
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      zoomAndPan="magnify"
      viewBox="0 0 375 149.999998"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
      </defs>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(17.115533, 83.876307)">
          <g>
            <path d="M 12.921875 0 L 23.410156 0 L 35.335938 -31.714844 L 27.71875 -31.714844 L 18.417969 -5.929688 L 17.917969 -5.929688 L 8.613281 -31.714844 L 1 -31.714844 Z M 12.921875 0 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(53.448103, 83.876307)">
          <g>
            <path d="M 19.726562 1 C 27.34375 1 33.960938 -3.121094 35.710938 -9.238281 L 29.464844 -10.238281 C 28.15625 -6.679688 24.347656 -4.121094 20.039062 -4.121094 C 14.171875 -4.121094 9.988281 -8.054688 9.488281 -13.921875 L 36.707031 -13.921875 L 36.769531 -15.855469 C 36.769531 -25.90625 29.902344 -32.710938 19.789062 -32.710938 C 9.488281 -32.710938 2.246094 -25.96875 2.246094 -15.917969 C 2.246094 -5.804688 9.425781 1 19.726562 1 Z M 9.425781 -18.042969 C 9.988281 -23.722656 13.984375 -27.65625 19.664062 -27.65625 C 25.160156 -27.65625 29.214844 -23.535156 29.589844 -18.042969 Z M 9.425781 -18.042969 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(92.340187, 83.876307)">
          <g>
            <path d="M 4.121094 0 L 11.175781 0 L 11.175781 -18.230469 C 11.175781 -23.160156 14.796875 -26.65625 19.414062 -26.65625 C 24.097656 -26.65625 26.96875 -23.035156 26.96875 -17.917969 L 26.96875 0 L 34.085938 0 L 34.085938 -19.164062 C 34.085938 -26.96875 29.277344 -32.710938 22.039062 -32.710938 C 17.480469 -32.710938 13.921875 -30.402344 11.675781 -26.28125 L 11.175781 -26.28125 L 11.175781 -31.714844 L 4.121094 -31.714844 Z M 4.121094 0 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(129.983715, 83.876307)">
          <g>
            <path d="M 15.605469 1 C 20.164062 1 23.722656 -1.3125 25.96875 -5.429688 L 26.46875 -5.429688 L 26.46875 0 L 33.585938 0 L 33.585938 -31.714844 L 26.46875 -31.714844 L 26.46875 -13.546875 C 26.46875 -8.613281 22.847656 -5.058594 18.292969 -5.058594 C 13.609375 -5.058594 10.675781 -8.675781 10.675781 -13.796875 L 10.675781 -31.714844 L 3.558594 -31.714844 L 3.558594 -12.609375 C 3.558594 -4.746094 8.367188 1 15.605469 1 Z M 15.605469 1 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(167.627255, 83.876307)">
          <g>
            <path d="M 19.726562 1 C 27.34375 1 33.960938 -3.121094 35.710938 -9.238281 L 29.464844 -10.238281 C 28.15625 -6.679688 24.347656 -4.121094 20.039062 -4.121094 C 14.171875 -4.121094 9.988281 -8.054688 9.488281 -13.921875 L 36.707031 -13.921875 L 36.769531 -15.855469 C 36.769531 -25.90625 29.902344 -32.710938 19.789062 -32.710938 C 9.488281 -32.710938 2.246094 -25.96875 2.246094 -15.917969 C 2.246094 -5.804688 9.425781 1 19.726562 1 Z M 9.425781 -18.042969 C 9.988281 -23.722656 13.984375 -27.65625 19.664062 -27.65625 C 25.160156 -27.65625 29.214844 -23.535156 29.589844 -18.042969 Z M 9.425781 -18.042969 " />
          </g>
        </g>
      </g>
      <g fill="#f85c3a" fillOpacity="1">
        <g transform="translate(206.508159, 83.876307)">
          <g>
            <path d="M 22.910156 0 L 30.777344 0 L 30.777344 -10.363281 L 36.082031 -10.363281 L 36.082031 -16.230469 L 30.777344 -16.230469 L 30.777344 -44.199219 L 19.351562 -44.199219 L 1.5 -14.609375 L 1.5 -10.363281 L 22.910156 -10.363281 Z M 8.988281 -16.230469 L 22.410156 -38.207031 L 22.910156 -38.207031 L 22.910156 -16.230469 Z M 8.988281 -16.230469 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(245.023699, 83.876307)">
          <g>
            <path d="M 9.675781 14.234375 C 15.792969 14.234375 19.289062 9.550781 22.660156 0.8125 L 35.335938 -31.714844 L 28.03125 -31.714844 L 19.539062 -9.050781 L 18.976562 -9.050781 L 8.738281 -31.714844 L 1.0625 -31.714844 L 16.480469 0.125 C 14.484375 4.933594 12.359375 8.429688 8.429688 8.429688 C 7.054688 8.429688 5.242188 8.117188 3.933594 7.679688 L 3.933594 13.359375 C 5.367188 13.859375 7.804688 14.234375 9.675781 14.234375 Z M 9.675781 14.234375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(281.418694, 83.876307)">
          <g>
            <path d="M 19.414062 1 C 29.714844 1 36.707031 -5.679688 36.707031 -15.855469 C 36.707031 -26.03125 29.714844 -32.710938 19.414062 -32.710938 C 9.113281 -32.710938 2.183594 -26.09375 2.183594 -15.855469 C 2.183594 -5.679688 9.175781 1 19.414062 1 Z M 19.414062 -5.058594 C 13.484375 -5.058594 9.238281 -9.550781 9.238281 -15.855469 C 9.238281 -22.164062 13.484375 -26.65625 19.414062 -26.65625 C 25.410156 -26.65625 29.652344 -22.164062 29.652344 -15.855469 C 29.652344 -9.550781 25.410156 -5.058594 19.414062 -5.058594 Z M 19.414062 -5.058594 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(320.185925, 83.876307)">
          <g>
            <path d="M 15.605469 1 C 20.164062 1 23.722656 -1.3125 25.96875 -5.429688 L 26.46875 -5.429688 L 26.46875 0 L 33.585938 0 L 33.585938 -31.714844 L 26.46875 -31.714844 L 26.46875 -13.546875 C 26.46875 -8.613281 22.847656 -5.058594 18.292969 -5.058594 C 13.609375 -5.058594 10.675781 -8.675781 10.675781 -13.796875 L 10.675781 -31.714844 L 3.558594 -31.714844 L 3.558594 -12.609375 C 3.558594 -4.746094 8.367188 1 15.605469 1 Z M 15.605469 1 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(110.629346, 117.432653)">
          <g>
            <path d="M 3.40625 -2.09375 L 3.40625 0 L 2.359375 0 L 2.359375 -2.0625 L 0.015625 -6.296875 L 1.078125 -6.296875 L 2.875 -3.234375 L 4.640625 -6.296875 L 5.71875 -6.296875 Z M 3.40625 -2.09375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(117.114109, 117.432653)">
          <g>
            <path d="M 1.265625 -5.40625 C 1.910156 -6.019531 2.703125 -6.328125 3.640625 -6.328125 C 4.585938 -6.328125 5.378906 -6.019531 6.015625 -5.40625 C 6.660156 -4.800781 6.984375 -4.046875 6.984375 -3.140625 C 6.984375 -2.242188 6.660156 -1.488281 6.015625 -0.875 C 5.378906 -0.257812 4.585938 0.046875 3.640625 0.046875 C 2.703125 0.046875 1.910156 -0.257812 1.265625 -0.875 C 0.628906 -1.488281 0.3125 -2.242188 0.3125 -3.140625 C 0.3125 -4.046875 0.628906 -4.800781 1.265625 -5.40625 Z M 3.65625 -5.375 C 3.03125 -5.375 2.492188 -5.160156 2.046875 -4.734375 C 1.609375 -4.304688 1.390625 -3.78125 1.390625 -3.15625 C 1.390625 -2.53125 1.613281 -2 2.0625 -1.5625 C 2.507812 -1.125 3.039062 -0.90625 3.65625 -0.90625 C 4.269531 -0.90625 4.796875 -1.125 5.234375 -1.5625 C 5.671875 -2 5.890625 -2.53125 5.890625 -3.15625 C 5.890625 -3.78125 5.671875 -4.304688 5.234375 -4.734375 C 4.796875 -5.160156 4.269531 -5.375 3.65625 -5.375 Z M 3.65625 -5.375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(125.157008, 117.432653)">
          <g>
            <path d="M 6.1875 -6.296875 L 6.1875 -2.609375 C 6.1875 -1.785156 5.941406 -1.132812 5.453125 -0.65625 C 4.960938 -0.1875 4.289062 0.046875 3.4375 0.046875 C 2.59375 0.046875 1.925781 -0.1875 1.4375 -0.65625 C 0.945312 -1.132812 0.703125 -1.785156 0.703125 -2.609375 L 0.703125 -6.296875 L 1.765625 -6.296875 L 1.765625 -2.609375 C 1.765625 -2.078125 1.914062 -1.660156 2.21875 -1.359375 C 2.519531 -1.054688 2.929688 -0.90625 3.453125 -0.90625 C 3.972656 -0.90625 4.378906 -1.054688 4.671875 -1.359375 C 4.972656 -1.660156 5.125 -2.078125 5.125 -2.609375 L 5.125 -6.296875 Z M 6.1875 -6.296875 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(132.785604, 117.432653)">
          <g>
            <path d="M 4.828125 0 L 3.671875 -1.90625 C 3.609375 -1.90625 3.515625 -1.90625 3.390625 -1.90625 L 1.890625 -1.90625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 3.390625 -6.296875 C 4.191406 -6.296875 4.8125 -6.109375 5.25 -5.734375 C 5.695312 -5.359375 5.921875 -4.832031 5.921875 -4.15625 C 5.921875 -3.644531 5.804688 -3.210938 5.578125 -2.859375 C 5.359375 -2.515625 5.035156 -2.265625 4.609375 -2.109375 L 6.03125 0 Z M 1.890625 -2.859375 L 3.390625 -2.859375 C 4.398438 -2.859375 4.90625 -3.28125 4.90625 -4.125 C 4.90625 -4.9375 4.398438 -5.34375 3.390625 -5.34375 L 1.890625 -5.34375 Z M 1.890625 -2.859375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(140.044928, 117.432653)">
          <g />
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(143.11618, 117.432653)">
          <g>
            <path d="M 5.140625 -3.09375 L 6.0625 -3.09375 L 6.0625 -0.78125 C 5.738281 -0.53125 5.351562 -0.328125 4.90625 -0.171875 C 4.46875 -0.0234375 4.039062 0.046875 3.625 0.046875 C 2.6875 0.046875 1.898438 -0.257812 1.265625 -0.875 C 0.628906 -1.488281 0.3125 -2.242188 0.3125 -3.140625 C 0.3125 -4.046875 0.632812 -4.800781 1.28125 -5.40625 C 1.925781 -6.019531 2.726562 -6.328125 3.6875 -6.328125 C 4.125 -6.328125 4.554688 -6.242188 4.984375 -6.078125 C 5.421875 -5.921875 5.796875 -5.707031 6.109375 -5.4375 L 5.5 -4.65625 C 4.976562 -5.132812 4.375 -5.375 3.6875 -5.375 C 3.039062 -5.375 2.492188 -5.160156 2.046875 -4.734375 C 1.609375 -4.304688 1.390625 -3.78125 1.390625 -3.15625 C 1.390625 -2.519531 1.613281 -1.984375 2.0625 -1.546875 C 2.507812 -1.117188 3.050781 -0.90625 3.6875 -0.90625 C 4.175781 -0.90625 4.660156 -1.050781 5.140625 -1.34375 Z M 5.140625 -3.09375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(150.609682, 117.432653)">
          <g>
            <path d="M 1.890625 -0.96875 L 4.609375 -0.96875 L 4.609375 0 L 0.828125 0 L 0.828125 -6.296875 L 1.890625 -6.296875 Z M 1.890625 -0.96875 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(156.166762, 117.432653)">
          <g>
            <path d="M 1.265625 -5.40625 C 1.910156 -6.019531 2.703125 -6.328125 3.640625 -6.328125 C 4.585938 -6.328125 5.378906 -6.019531 6.015625 -5.40625 C 6.660156 -4.800781 6.984375 -4.046875 6.984375 -3.140625 C 6.984375 -2.242188 6.660156 -1.488281 6.015625 -0.875 C 5.378906 -0.257812 4.585938 0.046875 3.640625 0.046875 C 2.703125 0.046875 1.910156 -0.257812 1.265625 -0.875 C 0.628906 -1.488281 0.3125 -2.242188 0.3125 -3.140625 C 0.3125 -4.046875 0.628906 -4.800781 1.265625 -5.40625 Z M 3.65625 -5.375 C 3.03125 -5.375 2.492188 -5.160156 2.046875 -4.734375 C 1.609375 -4.304688 1.390625 -3.78125 1.390625 -3.15625 C 1.390625 -2.53125 1.613281 -2 2.0625 -1.5625 C 2.507812 -1.125 3.039062 -0.90625 3.65625 -0.90625 C 4.269531 -0.90625 4.796875 -1.125 5.234375 -1.5625 C 5.671875 -2 5.890625 -2.53125 5.890625 -3.15625 C 5.890625 -3.78125 5.671875 -4.304688 5.234375 -4.734375 C 4.796875 -5.160156 4.269531 -5.375 3.65625 -5.375 Z M 3.65625 -5.375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(164.20966, 117.432653)">
          <g>
            <path d="M 0.828125 -6.296875 L 3.5625 -6.296875 C 4.21875 -6.296875 4.734375 -6.15625 5.109375 -5.875 C 5.484375 -5.601562 5.671875 -5.21875 5.671875 -4.71875 C 5.671875 -4.363281 5.570312 -4.0625 5.375 -3.8125 C 5.175781 -3.570312 4.910156 -3.410156 4.578125 -3.328125 C 4.992188 -3.253906 5.320312 -3.070312 5.5625 -2.78125 C 5.800781 -2.5 5.921875 -2.148438 5.921875 -1.734375 C 5.921875 -1.191406 5.71875 -0.765625 5.3125 -0.453125 C 4.90625 -0.148438 4.34375 0 3.625 0 L 0.828125 0 Z M 1.890625 -5.359375 L 1.890625 -3.6875 L 3.515625 -3.6875 C 3.847656 -3.6875 4.109375 -3.757812 4.296875 -3.90625 C 4.492188 -4.0625 4.59375 -4.269531 4.59375 -4.53125 C 4.59375 -4.800781 4.492188 -5.003906 4.296875 -5.140625 C 4.109375 -5.285156 3.847656 -5.359375 3.515625 -5.359375 Z M 1.890625 -2.75 L 1.890625 -0.9375 L 3.515625 -0.9375 C 3.929688 -0.9375 4.253906 -1.015625 4.484375 -1.171875 C 4.710938 -1.335938 4.828125 -1.566406 4.828125 -1.859375 C 4.828125 -2.140625 4.707031 -2.359375 4.46875 -2.515625 C 4.238281 -2.671875 3.921875 -2.75 3.515625 -2.75 Z M 1.890625 -2.75 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(171.315878, 117.432653)">
          <g>
            <path d="M 4.890625 -1.421875 L 1.71875 -1.421875 L 1.109375 0 L 0.015625 0 L 2.796875 -6.296875 L 3.875 -6.296875 L 6.640625 0 L 5.5 0 Z M 4.484375 -2.359375 L 3.296875 -5.171875 L 2.109375 -2.359375 Z M 4.484375 -2.359375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(178.710308, 117.432653)">
          <g>
            <path d="M 1.890625 -0.96875 L 4.609375 -0.96875 L 4.609375 0 L 0.828125 0 L 0.828125 -6.296875 L 1.890625 -6.296875 Z M 1.890625 -0.96875 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(184.267391, 117.432653)">
          <g />
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(187.338645, 117.432653)">
          <g>
            <path d="M 3.640625 0 L 2.53125 0 L 0.03125 -6.296875 L 1.171875 -6.296875 L 3.109375 -1.171875 L 5.078125 -6.296875 L 6.1875 -6.296875 Z M 3.640625 0 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(194.300757, 117.432653)">
          <g>
            <path d="M 5.328125 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.640625 L 4.96875 -3.640625 L 4.96875 -2.6875 L 1.890625 -2.6875 L 1.890625 -0.953125 L 5.4375 -0.953125 L 5.4375 0 L 0.828125 0 L 0.828125 -6.296875 L 5.328125 -6.296875 Z M 5.328125 -5.34375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(200.956643, 117.432653)">
          <g>
            <path d="M 6.296875 0 L 5.25 0 L 1.890625 -4.515625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 1.875 -6.296875 L 5.25 -1.765625 L 5.25 -6.296875 L 6.296875 -6.296875 Z M 6.296875 0 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(208.837429, 117.432653)">
          <g>
            <path d="M 6.1875 -6.296875 L 6.1875 -2.609375 C 6.1875 -1.785156 5.941406 -1.132812 5.453125 -0.65625 C 4.960938 -0.1875 4.289062 0.046875 3.4375 0.046875 C 2.59375 0.046875 1.925781 -0.1875 1.4375 -0.65625 C 0.945312 -1.132812 0.703125 -1.785156 0.703125 -2.609375 L 0.703125 -6.296875 L 1.765625 -6.296875 L 1.765625 -2.609375 C 1.765625 -2.078125 1.914062 -1.660156 2.21875 -1.359375 C 2.519531 -1.054688 2.929688 -0.90625 3.453125 -0.90625 C 3.972656 -0.90625 4.378906 -1.054688 4.671875 -1.359375 C 4.972656 -1.660156 5.125 -2.078125 5.125 -2.609375 L 5.125 -6.296875 Z M 6.1875 -6.296875 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(216.466027, 117.432653)">
          <g>
            <path d="M 5.328125 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.640625 L 4.96875 -3.640625 L 4.96875 -2.6875 L 1.890625 -2.6875 L 1.890625 -0.953125 L 5.4375 -0.953125 L 5.4375 0 L 0.828125 0 L 0.828125 -6.296875 L 5.328125 -6.296875 Z M 5.328125 -5.34375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(223.121913, 117.432653)">
          <g />
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(226.193167, 117.432653)">
          <g>
            <path d="M 5.046875 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.53125 L 4.75 -3.53125 L 4.75 -2.578125 L 1.890625 -2.578125 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 5.0625 -6.296875 Z M 5.046875 -5.34375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(232.119522, 117.432653)">
          <g>
            <path d="M 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 1.890625 -6.296875 Z M 1.890625 0 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(235.587064, 117.432653)">
          <g>
            <path d="M 6.296875 0 L 5.25 0 L 1.890625 -4.515625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 1.875 -6.296875 L 5.25 -1.765625 L 5.25 -6.296875 L 6.296875 -6.296875 Z M 6.296875 0 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(243.467856, 117.432653)">
          <g>
            <path d="M 0.828125 -6.296875 L 3.40625 -6.296875 C 4.351562 -6.296875 5.132812 -6 5.75 -5.40625 C 6.363281 -4.8125 6.671875 -4.054688 6.671875 -3.140625 C 6.671875 -2.234375 6.359375 -1.484375 5.734375 -0.890625 C 5.117188 -0.296875 4.335938 0 3.390625 0 L 0.828125 0 Z M 1.890625 -5.34375 L 1.890625 -0.953125 L 3.4375 -0.953125 C 4.050781 -0.953125 4.5625 -1.15625 4.96875 -1.5625 C 5.382812 -1.976562 5.59375 -2.503906 5.59375 -3.140625 C 5.59375 -3.773438 5.378906 -4.300781 4.953125 -4.71875 C 4.535156 -5.132812 4.015625 -5.34375 3.390625 -5.34375 Z M 1.890625 -5.34375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(251.195529, 117.432653)">
          <g>
            <path d="M 5.328125 -5.34375 L 1.890625 -5.34375 L 1.890625 -3.640625 L 4.96875 -3.640625 L 4.96875 -2.6875 L 1.890625 -2.6875 L 1.890625 -0.953125 L 5.4375 -0.953125 L 5.4375 0 L 0.828125 0 L 0.828125 -6.296875 L 5.328125 -6.296875 Z M 5.328125 -5.34375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(257.851415, 117.432653)">
          <g>
            <path d="M 4.828125 0 L 3.671875 -1.90625 C 3.609375 -1.90625 3.515625 -1.90625 3.390625 -1.90625 L 1.890625 -1.90625 L 1.890625 0 L 0.828125 0 L 0.828125 -6.296875 L 3.390625 -6.296875 C 4.191406 -6.296875 4.8125 -6.109375 5.25 -5.734375 C 5.695312 -5.359375 5.921875 -4.832031 5.921875 -4.15625 C 5.921875 -3.644531 5.804688 -3.210938 5.578125 -2.859375 C 5.359375 -2.515625 5.035156 -2.265625 4.609375 -2.109375 L 6.03125 0 Z M 1.890625 -2.859375 L 3.390625 -2.859375 C 4.398438 -2.859375 4.90625 -3.28125 4.90625 -4.125 C 4.90625 -4.9375 4.398438 -5.34375 3.390625 -5.34375 L 1.890625 -5.34375 Z M 1.890625 -2.859375 " />
          </g>
        </g>
      </g>
    </svg>
  );
}
