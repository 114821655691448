import { create } from 'zustand';

type LocationStore = {
  location: string;
  parentLocation: string;
  setParentLocation: (location: string) => void;
  setLocation: (location: string) => void;
};

export const useLocationStore = create<LocationStore>(set => ({
  location: '',
  parentLocation: '',
  setParentLocation: (parentLocation: string) => set({ parentLocation }),
  setLocation: (location: string) => set({ location }),
}));
