import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Venue } from '../types';

export const deleteVenue = ({ venueId }: { venueId: number }) => {
  return axios.delete(`/venue/DeleteVenue/${venueId}`);
};

type UseDeleteVenueOptions = {
  config?: MutationConfig<typeof deleteVenue>;
};

export const useDeleteVenue = ({ config }: UseDeleteVenueOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async deletedVenue => {
      await queryClient.cancelQueries(['venues', deletedVenue.venueId]);

      const previousVenues = queryClient.getQueryData<Venue[]>(['venues']);

      queryClient.setQueryData(
        ['venues'],
        previousVenues?.filter(display => display?.venueId !== deletedVenue.venueId)
      );

      return { previousVenues };
    },
    onError: (_, __, context: any) => {
      if (context?.previousVenues) {
        queryClient.setQueryData(['venues'], context.previousVenues);
      }

      toast({
        variant: 'destructive',
        title: 'Failed to delete venue',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['venues']);
      queryClient.refetchQueries(['venues']);

      toast({
        variant: 'success',
        title: 'Venue deleted successfully!',
      });
    },
    ...config,
    mutationFn: ({ venueId }) => deleteVenue({ venueId }),
  });
};
