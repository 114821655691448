export function SearchSkeleton() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 animate-pulse">
      <div className="bg-card shadow-md rounded-lg flex flex-col justify-between h-full">
        <div className="w-full h-48 bg-zinc-200"></div>
        <div className="p-4">
          <div className="h-4 bg-zinc-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-zinc-200 rounded w-3/4 mb-4"></div>
          <div className="h-24 bg-zinc-200 rounded"></div>
        </div>
        <div className="p-4 mt-auto">
          <div className="h-10 w-24 bg-zinc-200 rounded"></div>
        </div>
      </div>
      <div className="bg-card shadow-md rounded-lg flex flex-col justify-between h-full">
        <div className="w-full h-48 bg-zinc-200"></div>
        <div className="p-4">
          <div className="h-4 bg-zinc-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-zinc-200 rounded w-3/4 mb-4"></div>
          <div className="h-24 bg-zinc-200 rounded"></div>
        </div>
        <div className="p-4 mt-auto">
          <div className="h-10 w-24 bg-zinc-200 rounded"></div>
        </div>
      </div>
      <div className="bg-card shadow-md rounded-lg flex flex-col justify-between h-full">
        <div className="w-full h-48 bg-zinc-200"></div>
        <div className="p-4">
          <div className="h-4 bg-zinc-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-zinc-200 rounded w-3/4 mb-4"></div>
          <div className="h-24 bg-zinc-200 rounded"></div>
        </div>
        <div className="p-4 mt-auto">
          <div className="h-10 w-24 bg-zinc-200 rounded"></div>
        </div>
      </div>
      <div className="bg-card shadow-md rounded-lg flex flex-col justify-between h-full">
        <div className="w-full h-48 bg-zinc-200"></div>
        <div className="p-4">
          <div className="h-4 bg-zinc-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-zinc-200 rounded w-3/4 mb-4"></div>
          <div className="h-24 bg-zinc-200 rounded"></div>
        </div>
        <div className="p-4 mt-auto">
          <div className="h-10 w-24 bg-zinc-200 rounded"></div>
        </div>
      </div>
      <div className="bg-card shadow-md rounded-lg flex flex-col justify-between h-full">
        <div className="w-full h-48 bg-zinc-200"></div>
        <div className="p-4">
          <div className="h-4 bg-zinc-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-zinc-200 rounded w-3/4 mb-4"></div>
          <div className="h-24 bg-zinc-200 rounded"></div>
        </div>
        <div className="p-4 mt-auto">
          <div className="h-10 w-24 bg-zinc-200 rounded"></div>
        </div>
      </div>
    </div>
  );
}
