import { Input } from '@/components/ui/input';
import { MapPin, Plus, Search } from 'lucide-react';
import { useState } from 'react';
import { useLocations } from '../api/getLocations';
import { LocationListItem } from '../components/LocationListItem';
import { useDeleteLocation } from '../api/deleteLocation';
import { useUpsertLocation } from '../api/upsertLocation';
import { queryClient } from '@/lib/react-query';
import { Location } from '../types';
import { UpsertLocationDialog } from '../components/UpsertLocationDialog';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';

export function Locations() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null | Partial<Location>>(null);

  const { toast } = useToast();

  const { data: locations = [] } = useLocations({});
  const { mutateAsync: deleteLocation } = useDeleteLocation({});
  const { mutateAsync: upsertLocation } = useUpsertLocation({
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries(['locations']);
        queryClient.refetchQueries(['locations']);

        toast({
          variant: 'success',
          title: selectedLocation?.locationId ? 'Location updated successfully!' : 'Location added successfully!',
        });
        setSelectedLocation(null);
      },
    },
  });

  function handleAddLocation() {
    setSelectedLocation({ locationId: 0, locationName: '', parentLocationId: 0 });
  }

  const formattedLocations = locations?.map(location => ({
    ...location,
    parentLocationName: locations.find(parentLocation => parentLocation.locationId === location.parentLocationId)?.locationName,
    locationName: location.locationName,
  }));

  const filteredLocations = formattedLocations?.filter(
    location => location.locationName?.toLowerCase().includes(searchTerm?.toLowerCase()) || location.locationName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  return (
    <>
      {selectedLocation && <UpsertLocationDialog onClose={() => setSelectedLocation(null)} locations={locations ?? []} location={selectedLocation} onSubmit={upsertLocation} />}
      <div className="flex space-x-4 py-6 pt-0 w-full h-full ">
        <div className="flex flex-col w-full ">
          <div className="flex w-full items-center bg-card px-6 h-fit justify-between z-20 fixed border-b border-b-input">
            <div className="max-w-6xl px-4  mx-auto py-4 flex items-center justify-between w-full">
              <div className="flex gap-x-1 items-center">
                <MapPin className="h-6 w-6 mr-1" />
                <h3 className="text-lg font-medium">Locations</h3>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="w-full">
                  <div className="relative w-full ">
                    <Search className="absolute h-4 w-4 left-2 top-1/2 -translate-y-1/2" />
                    <Input onChange={event => setSearchTerm(event.target.value)} placeholder="Search for location name" className="pl-8" />
                  </div>
                </div>
                <Button variant="default" className="" size="sm" onClick={handleAddLocation}>
                  <Plus className="h-4 w-4 mr-1" />
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className="space-y-2  p-6 pt-0 mt-24">
            <div className="flex flex-col space-y-2 py-6">
              {filteredLocations?.map(location => (
                <LocationListItem key={location.locationId} location={location} onDelete={deleteLocation} onEdit={() => setSelectedLocation(location)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
