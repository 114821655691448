import { Link, useParams } from 'react-router-dom';
import { ImageGallery } from '../components/ImageGallery';
import { useVenue } from '../api/getVenue';
import { ArrowLeft, Clock, MapPin } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { GoogleMap } from '@/components/GoogleMap/GoogleMap';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Fragment, useState } from 'react';
import { SendOffer } from '../components/SendOffer';
import storage from '@/utils/storage';
import { ApproveReviewButton } from '../components/ApproveReviewButton';
import { useVenueExtra } from '@/features/venueExtra/api/getVenueExtra';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';

const details = {
  exhibitor: {
    title: 'Exhibitor details',
    children: {
      exhibitorSpaceIndoor: 'Exhibition space indoors, sqm gross',
      exhibitorSpaceOutdoor: 'Exhibition space outdoors, sqm gross',
      exhibitorMaxExhibitors: 'Number of exhibitors',
      exhibitorMaxVisitors: 'Number of visitors',
      exhibitorHalls: 'Number of halls',
    },
  },
  conference: {
    title: 'Conference details',
    children: {
      conferenceRooms: 'Number of conference rooms',
      conferenceLargestRoom: 'Largest conference room, sqm gross',
      conferenceMaxDelegates: 'Max delegates',
    },
  },
  hotel: {
    title: 'Hotel details',
    children: {
      onsiteHotelRooms: 'On-site hotel rooms',
    },
  },
};

export function Venue() {
  const { id } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState(false);

  const { admin, venueGroupId } = storage.getUser();
  const navigate = useNavigate();

  const { data: venue } = useVenue({
    venueId: Number(id),
  });

  const { data: venueExtra } = useVenueExtra({ venueId: Number(id) });

  const links = venueExtra?.filter(ve => ve.type === 'link');
  const images = venueExtra?.filter(ve => ve.type === 'image');
  const ingress = venue?.description && venue.description?.length > 350 ? venue?.description?.substring(0, 350) + '...' : venue?.description;
  const description = ingress ? venue?.description?.substring(350) : venue?.description;

  const goBack = () => navigate(-1);

  return (
    <div className="h-full p-6">
      <div className="max-w-6xl m-auto space-y-4 lg:py-4">
        <div className="flex items-start md:items-center md:flex-row flex-col gap-2 justify-between">
          <div className="flex items-center gap-x-2">
            <Button variant="ghost" onClick={goBack} className="flex items-center p-0">
              <ArrowLeft className="h-4 w-4 mr-2 mt-1 bg-muted" />
            </Button>
            <h1 className="font-semibold text-xl">{venue?.venueName}</h1>
            {venue?.awaitingReview && (
              <span className="text-sky-800 flex font-semibold text-xs items-center border border-sky-200 bottom-2 bg-sky-50 p-1  px-3 rounded-full left-2">
                <Clock className="h-4 w-4 mr-1 text-sky-600" />
                Is a waiting review
              </span>
            )}
          </div>

          <div className="flex items-center gap-x-2">
            {admin && !venueGroupId && venue?.awaitingReview && <ApproveReviewButton venueId={venue?.venueId} />}
            <SendOffer />
          </div>
        </div>
        <div>
          <ImageGallery images={images ?? []} editMode={false} />
        </div>

        <div className="flex items-left flex-col justify-center space-y-2">
          <h2 className="font-semibold text-lg">Location</h2>
          <h6>Address</h6>
          <div className="flex items-center">
            <MapPin className="h-4 w-4 mr-1" />
            {venue?.address}
          </div>
          <div>
            <div className="h-4" />
            {venue?.address && <GoogleMap address={venue?.address ?? ''} />}
          </div>
        </div>
        <Separator />
        <div className="flex items-left flex-col justify-center space-y-2">
          <h2 className="font-semibold text-lg">Description</h2>
          <div dangerouslySetInnerHTML={{ __html: ingress ?? '' }} />
          {description && description?.length > 350 && (
            <Collapsible open={showMore}>
              <CollapsibleContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description ?? '',
                  }}
                />
              </CollapsibleContent>

              <CollapsibleTrigger onClick={() => setShowMore(prev => !prev)}>
                {showMore ? <span className="underline">Read less</span> : <span className="underline">Read more</span>}
              </CollapsibleTrigger>
            </Collapsible>
          )}
        </div>
        <Separator />
        <div className="w-full space-y-4 py-4">
          {Object.entries(details)
            .filter(([_, { children }]) => Object.entries(children).some(([childKey]) => venue?.[childKey as keyof typeof Venue] !== 0))
            .map(([key, { title, children }], index) => (
              <Fragment key={key}>
                <div key={key} className="flex flex-col items-start space-y-2  py-3 w-full">
                  <label className="text-md font-semibold">{title}</label>
                  {
                    <div className="w-full space-y-3">
                      {Object.entries(children)
                        .filter(([childKey]) => venue?.[childKey as keyof typeof Venue] !== 0)
                        .map(([childKey, childValue]) => (
                          <div key={childKey} className="flex items-center space-x-2">
                            <span className="w-1/2">{childValue}</span>
                            <span>{venue?.[childKey as keyof typeof Venue]}</span>
                          </div>
                        ))}
                    </div>
                  }
                </div>
                {index < 2 && <Separator />}
              </Fragment>
            ))}
        </div>
      </div>
      {!!links?.length && (
        <div className="bg-neutral-100 p-8">
          <div className="max-w-6xl m-auto">
            <h6 className="font-semibold text-md mb-4">Links</h6>
            {links?.map(link => (
              <a href={link?.value} target="_blank" rel="noreferrer">
                {link.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
