import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@/utils';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

interface ComboboxProps {
  options: {
    value: any;
    label: string;
  }[];
  value: string | any;
  onChange: (value: any) => void;
  disabled?: boolean;
  placeholder?: string;
}

export function Combobox({ options, value, onChange, disabled, placeholder }: ComboboxProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className='w-full'>
        <Button disabled={disabled} variant="outline" role="combobox" aria-expanded={open} className="w-full justify-between">
          <span className="truncate">
            {value ? options.find(option => option.value.toString() === value.toString())?.label : placeholder ?? 'Select option...'}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full   p-0" align="end">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No option found.</CommandEmpty>
          {options.length > 0 && (
            <CommandGroup className="max-h-64 overflow-y-auto">
              {options.map(option => (
                <CommandItem
                  key={option.value.toString()}
                  value={option.label.toString()}
                  onSelect={_ => {
                    onChange(option.value);
                    setOpen(false);
                  }}
                >
                  <Check className={cn('mr-2 h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')} />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
