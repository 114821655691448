import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Venue } from '../types';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { MapPin } from 'lucide-react';

interface VenueCardProps {
  venue: Venue;
}

export function VenueCard({ venue }: VenueCardProps) {
  const headerImage = venue.venueExtra?.find(extra => extra.name === 'main')?.value;
  const ingress = venue.description?.substring(0, 150) + '...';

  return (
    <Card className="bg-white shadow-md rounded-lg flex flex-col justify-between">
      <div>
        <Link to={`/venues/${venue.venueId}`}>
          <img src={headerImage} alt={venue.venueName} className="w-full h-48 object-cover" />
        </Link>
        <CardContent className="p-4 ">
          <h2 className="text-lg font-bold">{venue.venueName}</h2>
          <span className="flex w-full items-center">
            <MapPin className="h-4 w-4 mr-2" />
            <a href={`https://www.google.com/maps/search/${venue.address?.replace(/\s+/g, '+')}`} target="_blank" rel="noreferrer" className="underline">
              {venue.address}
            </a>
          </span>
          <p className="mt-4 max-h-48 overflow-hidden" dangerouslySetInnerHTML={{ __html: ingress }}></p>
        </CardContent>
      </div>

      <CardFooter className="flex justify-between p-4 mt-full">
        <Button>
          <Link to={`/venues/${venue.venueId}`}>Read more</Link>
        </Button>
      </CardFooter>
    </Card>
  );
}
