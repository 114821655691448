import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '../types';

export const getUsers = (): Promise<User[]> => {
  return axios.get(`/user/getAll`);
};

type QueryFnType = typeof getUsers;

type UserUsersOption = {
  config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ config }: UserUsersOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['users'],
    queryFn: () => getUsers(),
    ...config,
  });
};
