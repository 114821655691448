import { useCallback, useEffect, useRef } from 'react';
import { usePrevious } from './usePrevious';

export function useHeightObserver(wrapperRef: any, contentRef: any, callback: any, route: string) {
  const prevHeight = usePrevious(wrapperRef?.current?.scrollHeight);
  const reforms = useRef(0);

  const memoizedCallback = useCallback(
    (height: any) => {
      callback(height);
    },
    [callback]
  );

  useEffect(() => {
    const observer = new ResizeObserver(_ => {
      reforms.current++;
      if (reforms.current < 10) {
        memoizedCallback(wrapperRef?.current?.scrollHeight);
      }
    });

    if (wrapperRef?.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef?.current) {
        observer.unobserve(wrapperRef.current);
        observer.disconnect();
        reforms.current = 0;
      }
    };
  }, [wrapperRef?.current, contentRef?.current, prevHeight, reforms.current]);

  useEffect(() => {
    reforms.current = 0;
  }, [route, reforms.current]);
}
