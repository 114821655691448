import { axios } from '@/lib/axios';
import { File } from '../types';

export function uploadFile(file: File, venueId: number) {
  return axios.post(`/storage/${venueId}`, file, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}
