import { Card } from '@/components/ui/card';
import { Service } from '../types';
import { DeleteButton } from '@/components/DeleteButton';
import { Button } from '@/components/ui/button';
import { Trash2Icon } from 'lucide-react';

interface ServiceListItemProps {
  service: Service;
  onDelete: (service: Service) => void;
  onEdit: (service: Service) => void;
}

export function ServiceListItem({ service, onDelete, onEdit }: ServiceListItemProps) {
  return (
    <Card className="max-w-6xl w-full mx-auto p-4 flex items-center justify-between">
      <div className="flex items-center gap-x-1">
        <h2 className="font-semibold">{service.name}</h2>
        of type
        <p>{service.type}</p>
      </div>
      <div className="flex gap-x-2">
        <Button variant="outline" onClick={() => onEdit(service)}>
          Edit
        </Button>
        <DeleteButton
          onDelete={() => onDelete(service)}
          ButtonComponent={
            <Button variant="destructive">
              <Trash2Icon className="h-4 w-4" />
            </Button>
          }
        />
      </div>
    </Card>
  );
}
