import { axios } from '@/lib/axios';
import storage from '@/utils/storage';

export type LoginCredentialsDTO = {
  userName: string;
  password: string;
  rememberMe: boolean;
  returnUrl: string;
  failedLogin: boolean;
};

export const login = async (data: LoginCredentialsDTO) => {
  try {
    const response = (await axios.post('/api/login', data)) as any;
    const token = response.token.token;
    const { userName, admin, firstName, lastName, email, userGuid, venueGroupId, userId } = response.token;

    storage.setToken(token);
    storage.setUser({
      userName,
      admin,
      firstName,
      lastName,
      email,
      userGuid,
      userId,
      venueGroupId,
    } as any);

    if (window.location.pathname !== '/login') {
      window.location.pathname = window.location.pathname;
    }
  } catch (error) {
    throw error;
  }
};
