import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { userRoutes } from './userRoutes';
import { adminRoutes } from './adminRoutes';
import { publicRoutes } from './public';
import storage from '@/utils/storage';

export const AppRoutes = () => {
  const token = storage.getToken();
  const user = storage.getUser();

  const protectedRoutes: RouteObject[] = user?.admin ? [...adminRoutes] : [...userRoutes];
  const routes: RouteObject[] = token ? [...protectedRoutes] : publicRoutes;
  const router = createBrowserRouter([...routes]);

  return <RouterProvider router={router} />;
};
