import { axios } from '@/lib/axios';

export type RestorePasswordDTO = {
  newPassword: string;
  resetToken: string;
};

export const restorePassword = async (data: RestorePasswordDTO) => {
  try {
    return axios.post('/api/submitpassword', data);
  } catch (error) {
    throw error;
  }
};
