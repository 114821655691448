import { Hotel, ParkingCircle, SpeechIcon, Theater, UtensilsCrossed } from 'lucide-react';

const exhibitionDetails = {
  exhibitorSpaceIndoor: 'Exhibition space indoors, sqm gross',
  exhibitorSpaceOutdoor: 'Exhibition space outdoors, sqm gross',
  exhibitorMaxExhibitors: 'Number of exhibitors',
  exhibitorMaxVisitors: 'Number of visitors',
  exhibitorHalls: 'Number of halls',
  exhibitorSpaceLargestHall: 'Largest hall, sqm gross',
};

const conferenceDetails = {
  conferenceRooms: 'Number of conference rooms',
  conferenceLargestRoom: 'Largest conference room, sqm gross',
  conferenceMaxDelegates: 'Max delegates',
};

const hotelDetails = {
  onsiteHotelRooms: 'No. of hotel rooms on-site',
};

const restaurantDetails = {
  restaurantTotalSeats: 'Total restaurant seats',
  restaurantCount: 'Number of restaurants',
  restaurantLargestSeat: 'Largest restaurant seat',
};

const parkingLotDetails = {
  parkingLots: 'Parking lots',
};

const venueDetails = [
  {
    title: 'Exhibition',
    icon: <Theater className="h-4 w-4" />,
    children: exhibitionDetails,
  },
  {
    title: 'Conference/congress',
    icon: <SpeechIcon className="h-4 w-4" />,
    children: conferenceDetails,
  },
  {
    title: 'Hotels on site',
    icon: <Hotel className="h-4 w-4" />,
    children: hotelDetails,
  },
  {
    title: 'Restaurants',
    icon: <UtensilsCrossed className="h-4 w-4" />,
    children: restaurantDetails,
  },
  {
    title: 'Parking',
    icon: <ParkingCircle className="h-4 w-4" />,
    children: parkingLotDetails,
  },
];

const links = ['Venue plan', 'Sustainability', 'Food & drink', 'Website', 'Conference halls'];

export { venueDetails, links };
