import { LoginForm } from '@/features/auth/components/LoginForm';
import { Card, CardDescription, CardTitle } from '@/components/ui/card';
import { Venue4YouLogo } from '@/components/Venue4YouLogo';
import { Separator } from '@/components/ui/separator';
import { Link } from 'react-router-dom';
import { cn } from '@/utils';

export function Login() {
  return (
    <div className={cn('bg-zinc-100 h-full w-full flex flex-col items-center justify-center', window.location === window.parent.location ? 'h-screen' : 'h-full  py-12')}>
      {window.location === window.parent.location && (
        <div className="w-80 ">
          <Venue4YouLogo />
        </div>
      )}
      <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
        <CardTitle className="text-center text-2xl font-bold">Login</CardTitle>
        <CardDescription className="text-center text-sm">Login with your email and password</CardDescription>
        <Separator />
        <LoginForm />
        <div className="flex flex-col space-y-4">
          <Link to="/forgot-password" className="underline text-sm">
            Forgot password?
          </Link>
          <Link to="/register" className="underline text-sm">
            Register here
          </Link>
        </div>
      </Card>
    </div>
  );
}
