import { ReactNode } from 'react';
import { Menu } from './Menu';
import { cn } from '@/utils';
import storage from '@/utils/storage';

export default function Layout({ children }: { children: ReactNode }) {
  const admin = storage.getUser()?.admin;
  return (
    <div className="flex flex-col w-screen h-screen ">
      {admin && (
        <div className=" border-y border-input bg-card  w-screen fixed z-20">
          <div className="w-full mx-auto max-w-6xl ">
            <Menu />
          </div>
        </div>
      )}
      <main className={cn('bg-background w-full basis-full h-full bg-zinc-100', admin ? 'mt-[42px]' : 'mt-0')}>{children}</main>
    </div>
  );
}
