import { axios } from '@/lib/axios';

export type UserDTO = {
  password: string;
  userName: string;
  firstName: string;
  lastName: string;
  title: string;
  organization: string;
  path: string;
};

export const register = async (data: UserDTO) => {
  const isEmbed = window.location !== window.parent.location;

  try {
    return axios.post('/api/register', {
      ...data,
      isEmbed,
      rememberMe: false,
      locationId: 0,
    });
  } catch (error) {
    console.error('Error registering user', error);
    throw error;
  }
};
