import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { User } from '../types';

export const deleteUser = ({ userId }: { userId: number }) => {
  return axios.delete(`/user/${userId}`);
};

type UseDeleteUserOptions = {
  config?: MutationConfig<typeof deleteUser>;
};

export const useDeleteUser = ({ config }: UseDeleteUserOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async deletedUser => {
      // Query key updated
      await queryClient.cancelQueries(['users']);

      const previousLocations = queryClient.getQueryData<User[]>(['users']);

      queryClient.setQueryData(
        ['users'],
        previousLocations?.filter(display => display?.userId !== deletedUser.userId)
      );

      return { previousLocations };
    },
    onError: (_, __, context: any) => {
      if (context?.previousLocations) {
        queryClient.setQueryData(['users'], context.previousLocations);
      }

      toast({
        variant: 'destructive',
        title: 'Failed to delete user',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      queryClient.refetchQueries(['users']);

      toast({
        variant: 'success',
        title: 'User deleted successfully!',
      });
    },
    ...config,
    mutationFn: ({ userId }) => deleteUser({ userId }),
  });
};
