import { Location } from '@/features/location/types';

const findMatchingParent = (location: Location | undefined, targetLocationId: number, locations: Location[]): boolean => {
  debugger;
  if (!location?.parentLocationId && location?.locationId !== targetLocationId) return false;
  if (location?.locationId === targetLocationId) return true;
  if (location?.parentLocationId) {
    const parent = locations.find(l => l.locationId === location.parentLocationId);
    return findMatchingParent(parent, targetLocationId, locations);
  }
  return false;
};

export function findLocationMatch(currentLocationId: number, targetLocationId: number, locations: Location[]): boolean {
  const perfectMatch = currentLocationId === targetLocationId;

  if (perfectMatch) return true;

  const currentLocation = locations.find(l => l.locationId === currentLocationId);
  const hasMatchingParent = findMatchingParent(currentLocation, targetLocationId, locations);

  return hasMatchingParent;
}
