import { Input } from '@/components/ui/input';
import { HelpingHand, Plus, Search } from 'lucide-react';
import { useState } from 'react';
import { ServiceListItem } from '../components/ServiceListItem';
import { queryClient } from '@/lib/react-query';
import { Service } from '../types';
import { UpsertServiceDialog } from '../components/UpsertServiceDialog';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useServices } from '../api/getServices';
import { useDeleteService } from '../api/deleteService';
import { useUpsertService } from '../api/upsertService';

export function Services() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedService, setSelectedLocation] = useState<Service | null | Partial<Service>>(null);

  const { toast } = useToast();

  const { data: services = [] } = useServices({});
  const { mutateAsync: deleteService } = useDeleteService({});
  const { mutateAsync: upsertService } = useUpsertService({
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries(['services']);
        queryClient.refetchQueries(['services']);

        toast({
          variant: 'success',
          title: selectedService?.serviceId ? 'Service updated successfully!' : 'Service added successfully!',
        });
        setSelectedLocation(null);
      },
    },
  });

  function handleAddLocation() {
    setSelectedLocation({ serviceId: 0, name: '', type: '' });
  }

  const filteredServices = services?.filter(
    service => service.name?.toLowerCase().includes(searchTerm?.toLowerCase()) || service.type?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  return (
    <>
      {selectedService && <UpsertServiceDialog onClose={() => setSelectedLocation(null)} service={selectedService} onSubmit={upsertService} />}
      <div className="flex space-x-4 py-6 pt-0 w-full h-full ">
        <div className="flex flex-col w-full ">
          <div className="flex w-full items-center bg-card px-6 h-fit justify-between fixed z-20  border-b border-b-input">
            <div className="max-w-6xl px-4  mx-auto py-4 flex items-center justify-between w-full">
              <div className="flex gap-x-1 items-center">
                <HelpingHand className="h-6 w-6 mr-1" />
                <h3 className="text-lg font-medium">Services</h3>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="w-full">
                  <div className="relative w-full ">
                    <Search className="absolute h-4 w-4 left-2 top-1/2 -translate-y-1/2" />
                    <Input onChange={event => setSearchTerm(event.target.value)} placeholder="Search for service name" className="pl-8" />
                  </div>
                </div>
                <Button variant="default" className="" size="sm" onClick={handleAddLocation}>
                  <Plus className="h-4 w-4 mr-1" />
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className="space-y-2  p-6 pt-0 mt-24">
            <div className="flex flex-col space-y-2 py-6">
              {filteredServices?.map(service => (
                <ServiceListItem key={service.serviceId} service={service} onDelete={deleteService} onEdit={service => setSelectedLocation(service)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
