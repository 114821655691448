import { Input } from '@/components/ui/input';
import { SearchIcon } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { useVenues } from '../api/getVenues';
import { useEffect, useMemo, useRef, useState } from 'react';
import { VenueCard } from '../components/VenueCard';
import { FilterMenu } from '../components/FilterMenu';
import { Filters } from '../types';
import { Venue4YouLogo } from '@/components/Venue4YouLogo';
import { cn } from '@/utils';
import useInView from '@/hooks/useElementOutOfViewPort';
import { useSearchQueryStore } from '@/stores/searchQueryStore';
import { Button } from '@/components/ui/button';
import { SearchSkeleton } from '../components/SearchSkeleton';
import { findLocationMatch } from '@/utils/findLocationMatch';
import { useLocations } from '@/features/location/api/getLocations';

export function Search() {
  const [searchTerm, setSearchTerm] = useState('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const setSearchQuery = useSearchQueryStore(state => state.setSearchQuery);
  // const searchQuery = useSearchQueryStore(state => state.searchQuery);

  const { data: venues, isLoading } = useVenues({});
  const { data: locations } = useLocations({});
  const { ref, inView } = useInView({ thresholds: [2] });

  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => {
    const entriesArray = Array.from(searchParams.entries());
    return entriesArray
      .filter(([key]) => !['searchTerm', 'domain', 'view', 'q', 'redirect'].includes(key))
      .reduce((acc, [key, value]) => {
        const decodedValue = decodeURIComponent(value as string);
        if (['continent', 'country', 'city'].includes(key)) {
          return { ...acc, [key]: decodedValue.split(',').map(Number) };
        }
        return { ...acc, [key]: decodedValue };
      }, {});
  }, [searchParams]);

  const filteredVenues = venues
    ?.filter(venue => venue?.published && !venue.awaitingReview)
    ?.filter(venue => {
      if (Object.keys(query).length > 0) {
        const filters = query as Filters;
        const locationValue = filters.city ?? filters.country ?? filters.continent;
        const locationId = locationValue?.[0] ?? null;

        if (locationId) {
          const locationMatch = findLocationMatch(venue.locationId, locationId, locations ?? []);

          if (!locationMatch) return false;
        }

        const nonLocationFilters = Object.entries(filters).filter(([key]) => !['continent', 'country', 'city', 'domain', 'view', 'q', 'redirect'].includes(key));

        return Object.entries(nonLocationFilters).every(([_, entry]) => {
          const [key, value] = entry;
          const venueValue = venue[key as keyof typeof venue];
          const operator = value?.toString().replace(/\d/g, '');

          console.log(key, value, venueValue);

          if (!venueValue || venueValue.toString() === '0') return false;

          switch (operator) {
            case 'TO': {
              const [min, max] = value?.toString()?.split('TO') as string[];
              if (parseInt(venueValue?.toString()) >= parseInt(min) && parseInt(venueValue?.toString()) <= parseInt(max)) return true;
              return false;
            }
            case 'GREATEROREQUAL': {
              const min = value?.toString()?.replace('GREATEROREQUAL', '');
              if (parseInt(min as string) <= parseInt(venueValue?.toString())) return true;
              return false;
            }
          }
        });
      }

      return true;
    })
    .filter(venue => venue.venueName?.toLowerCase().includes(searchTerm?.toLowerCase()) || venue.description?.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearch = () => {
    const inputValue = inputRef.current?.value;
    const newSearchParams = searchParams;
    newSearchParams.set('searchTerm', encodeURIComponent(inputValue as string));
    setSearchParams(newSearchParams);
    setSearchTerm(inputValue ?? '');
    setSearchQuery(newSearchParams.toString());
  };

  return (
    <div className={cn('space-y-4 overflow-x-hidden  bg-zinc-100 flex flex-col')}>
      <div ref={ref} className="w-full relative bg-gradient-to-r pb-6 from-neutral-800 to-neutral-700 bg-orange-100">
        <div className="sm:max-w-3xl max-w-md px-4 pb-6 mx-auto space-y-2 text-white ">
          <img
            src="https://images.unsplash.com/photo-1621685743771-fd5e13734ae6?q=80&w=2053&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="absolute top-0 left-0 w-screen h-full object-cover opacity-5"
          />
          <div className={cn('text-center px-6 pb-6 space-y-6 mx-auto', window.location !== window?.parent?.location && 'py-6')}>
            {window.location === window?.parent?.location && (
              <div className="sm:w-96 w-full mx-auto">
                <Venue4YouLogo mono />
              </div>
            )}
            <h1 className="text-3xl font-bold">Welcome to the world of Venue4you!</h1>
            <p>Fill in your event requirements and get offers from suitable venues around the world. Thousands of venues are waiting to host your event!</p>
            <p>
              Look at the venue information when the search result is displayed, choose the venue or venues you are interested in and click get an offer. Then fill in suitable
              dates and get offers from the selected venues.
            </p>
          </div>
          <div
            className={cn(
              'h-14 md:max-w-3xl max-w-[90%] -translate-x-1/2 left-1/2  translate-y-2  bg-transparent text-foreground rounded-full  ',
              !inView ? 'fixed bottom-10 left-1/2 md:w-1/2 w-[95%] -translate-x-1/2 shadow-2xl' : 'absolute w-full '
            )}
          >
            <div className="   h-full  border-input border items-center flex rounded-full shadow-sm p-2 px-3 bg-card w-full ">
              <div className=" w-full flex items-center px-3 rounded-md">
                <SearchIcon className="h-4 w-4 mr-2" />
                <Input
                  className="border-0 ring-0 ring-brand focus:ring-0 focus:border-0 w-full bg-card"
                  onKeyDown={e => e.key === 'Enter' && handleSearch()}
                  ref={inputRef}
                  placeholder="Search for venue"
                />
              </div>
              <Button onClick={handleSearch} variant="default" className="mr-2 rounded-full bg-brand hover:bg-brand" size="sm">
                Search
              </Button>
              <FilterMenu />
            </div>
          </div>
        </div>
      </div>

      <div id="search-grid" className="p-6 py-6 space-y-4 min-h-48">
        <span className="font-semibold mb-4 text-sm">Matches found: {filteredVenues?.length}</span>
        {!isLoading ? (
          <div className="grid md:grid-cols-3 2xl:grid-cols-5 lg:grid-cols-4 gap-4 grid-cols-1">
            {filteredVenues?.splice(0, 50)?.map(venue => (
              <VenueCard key={venue.venueId} venue={venue} />
            ))}
          </div>
        ) : (
          <SearchSkeleton />
        )}
      </div>
    </div>
  );
}
