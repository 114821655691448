import { Plus, Search, Users2 } from 'lucide-react';
import { useUsers } from '../api/getUsers';
import { useState } from 'react';
import { Input } from '@/components/ui/input';
import { UserListItem } from '../components/UserListItem';
import { useDeleteUser } from '../api/deleteUser';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { User } from '../types';
import storage from '@/utils/storage';
import { useUsersByVenuGroup } from '../api/getUserByVenueGroup';

export function Users() {
  const [searchTerm, setSearchTerm] = useState('');

  const { admin, venueGroupId } = storage.getUser();
  const { data: users } = admin && venueGroupId === null ? useUsers({}) : useUsersByVenuGroup({ venueGroupId });
  const { mutateAsync: deleteUser } = useDeleteUser({});

  const filteredUsers = users?.filter(
    (user: User) =>
      user.userName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user.organization?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user.title?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  return (
    <div className="flex space-x-4 py-6 pt-0 w-full h-full ">
      <div className="flex flex-col w-full ">
        <div className="flex w-full items-center bg-card px-6 h-fit justify-between fixed z-20  border-b border-b-input">
          <div className="max-w-6xl px-4  mx-auto py-4 flex items-center justify-between w-full">
            <div className="flex gap-x-1 items-center">
              <Users2 className="h-6 w-6 mr-1" />
              <h3 className="text-lg font-medium">Users</h3>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="w-full">
                <div className="relative w-full ">
                  <Search className="absolute h-4 w-4 left-2 top-1/2 -translate-y-1/2" />
                  <Input onChange={event => setSearchTerm(event.target.value)} placeholder="Search for user name" className="pl-8" />
                </div>
              </div>
              <Link to="/users/create" className="flex items-center gap-x-2">
                <Button variant="default" className="" size="sm">
                  <Plus className="h-4 w-4 mr-1" />
                  Add
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="space-y-2  p-6 pt-0 mt-24">
          <div className="flex flex-col space-y-2 py-6">
            {filteredUsers?.map(user => (
              <UserListItem key={user.userId} user={user} onDelete={deleteUser} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
