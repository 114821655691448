import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { VenueExtra } from '@/features/venueExtra/types';

import { Trash2Icon, Upload } from 'lucide-react';

interface ImageFileToolbarProps {
  onDelete: (venueExtra: Partial<VenueExtra>) => void;
  onChange: (venueExtra: Partial<VenueExtra>) => void;
  venueExtra: Partial<VenueExtra>;
}

export function ImageFileToolbar({ onDelete, onChange, venueExtra }: ImageFileToolbarProps) {
  return (
    <div className="flex justify-end space-x-2">
      {venueExtra?.value ? (
        <Tooltip>
          <TooltipTrigger type="button">
            <Button size="sm" type="button" onClick={() => onDelete(venueExtra)}>
              <Trash2Icon className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Remove the image</p>
          </TooltipContent>
        </Tooltip>
      ) : (
        <>
          <input
            id={'file' + venueExtra?.name}
            type="file"
            hidden
            onChange={e => {
              const file = e.target.files?.[0];
              file && onChange({ ...venueExtra, name: venueExtra?.name, value: URL.createObjectURL(file), file, type: venueExtra.type });
            }}
          />
          <Tooltip>
            <TooltipTrigger type="button">
              <label className="cursor-pointer bg-black block py-2.5 px-3 rounded-md text-white" htmlFor={'file' + venueExtra?.name}>
                <Upload className="h-4 w-4 m-auto" />
              </label>
            </TooltipTrigger>
            <TooltipContent>
              <p>Upload image</p>
            </TooltipContent>
          </Tooltip>
        </>
      )}
    </div>
  );
}
