import { z } from 'zod';
import { Service } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

interface UpsertServiceDialogProps {
  service: Service | Partial<Service>;
  onClose: () => void;
  onSubmit: (
    service:
      | Service
      | {
          type: string;
          name: string;
        }
  ) => void;
}

const formSchema = z.object({
  name: z.string(),
  type: z.string(),
});

export function UpsertServiceDialog({ service, onSubmit, onClose }: UpsertServiceDialogProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: service.name,
      type: service.type,
    },
  });

  function handleSubmit(data: z.infer<typeof formSchema>) {
    onSubmit({
      ...service,
      ...data,
    });
  }

  return (
    <Dialog open onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{service.serviceId ? 'Edit' : 'Create'} Service</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-8" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Service name</FormLabel>
                  <FormControl>
                    <Input placeholder="Venue name.." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Service type</FormLabel>
                  <FormControl>
                    <Input placeholder="Service name.." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">{service.serviceId ? 'Update' : 'Create'}</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
