import { axios } from '@/lib/axios';

export type ForgetPasswordDTO = {
  username: string;
  baseURL: string;
};

export const forgotPassword = async (data: ForgetPasswordDTO) => {
  const baseURL = window.location !== window.parent.location ? 'https://jirango.com/cview/web/a6fdab00' : window.location.origin;
  try {
    return axios.post(`/api/resetpassword/${data.username}?baseUrl=${baseURL ?? ''}`);
  } catch (error) {
    throw error;
  }
};
