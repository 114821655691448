import { Button } from '@/components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import { useCreateVenue } from '../api/createVenue';
import { useLocations } from '@/features/location/api/getLocations';
import { Combobox } from '@/components/ui/combobox';
import TinyMCE from '@/components/tinyMCE';
import { GoogleMap } from '@/components/GoogleMap/GoogleMap';
import { ImageGallery } from '../components/ImageGallery';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Building2, Link } from 'lucide-react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import uuid4 from 'uuid4';
import { uploadFile } from '@/features/storage/api/uploadFile';
import { useUpsertVenueExtra } from '@/features/venueExtra/api/upsertVenueExtra';
import { useToast } from '@/components/ui/use-toast';
import { links, venueDetails } from '../constants';
import { useMemo, useState } from 'react';
import { Location } from '@/features/location/types';
import { getLocationLeaf } from '@/utils/getLocationLeaf';

const formSchema = z.object({
  venueName: z.string().min(3, {
    message: 'Venue name must be at least 3 characters long.',
  }),
  address: z.string(),
  locationId: z.number(),
  exhibitorSpaceIndoor: z.number().optional(),
  exhibitorSpaceOutdoor: z.number().optional(),
  exhibitorMaxExhibitors: z.number().optional(),
  exhibitorMaxVisitors: z.number().optional(),
  exhibitorHalls: z.number().optional(),
  exhibitorSpaceLargestHall: z.number().optional(),
  conferenceRooms: z.number().optional(),
  conferenceLargestRoom: z.number().optional(),
  conferenceMaxDelegates: z.number().optional(),
  onsiteHotelRooms: z.number().optional(),
  offsiteHotelRooms3star: z.number().optional(),
  offsiteHotelRooms4star: z.number().optional(),
  offsiteHotelRooms5star: z.number().optional(),
  parkingLots: z.number().optional(),
  restaurantTotalSeats: z.number().optional(),
  restaurantCount: z.number().optional(),
  restaurantLargestSeat: z.number().optional(),
  description: z.string().optional(),
  published: z.nullable(z.boolean()).optional(),
  venueExtras: z
    .array(
      z.object({
        type: z.union([z.literal('image'), z.literal('link')]),
        name: z.string(),
        value: z.string().optional(),
        file: z.union([z.instanceof(File), z.string(), z.null()]).optional(),
      })
    )
    .optional(),
});

export function VenueCreate() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [isCreating, setIsCreating] = useState(false);

  const { mutateAsync: upsertVenueExtra } = useUpsertVenueExtra({});
  const { mutateAsync: createVenue } = useCreateVenue({});
  const { data: locations } = useLocations({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      venueName: '',
    },
  });

  const locationLeafs: Location[] = useMemo(
    () =>
      locations?.reduce((acc, location): any => {
        if (!location.parentLocationId) return acc;
        return [...acc, getLocationLeaf(location, locations)];
      }, []) ?? [],
    [locations]
  );

  async function handleSubmit({ venueExtras, ...values }: z.infer<typeof formSchema>) {
    try {
      setIsCreating(true);
      // Create venue
      const newVenue = await createVenue(values as any);

      toast({
        variant: 'success',
        title: 'Venue created successfully!',
      });

      const newVenueExtras = venueExtras?.map((venueExtra: any) => ({ ...venueExtra, venueId: newVenue.venueId }));
      // Upload images and create venue extras
      for (const venueExtra of newVenueExtras ?? []) {
        if (venueExtra.type === 'image' && venueExtra.file) {
          try {
            const fileDto = {
              id: uuid4(),
              name: venueExtra.name + '.jpg',
              size: 0,
              type: 'image',
              srcSet: 'string',
              url: 'string',
              created: new Date(),
              file: venueExtra.file,
            };

            const file: any = await uploadFile(fileDto, newVenue.venueId);
            venueExtra.value = (file.url as string) ?? '';
          } catch (error) {
            navigate(`/venues/edit/${newVenue.venueId}`);
            toast({
              variant: 'destructive',
              title: 'Something went wrong during image upload! The image might be too large. Please try with a smaller image.',
            });
          }
        }
        await upsertVenueExtra(venueExtra);
      }

      navigate(`/venues/edit/${newVenue.venueId}`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreating(false);
    }
  }

  // const isLoading = isCreatingVenue || isUpsertingVenueExtra;

  return (
    <div>
      <div className="">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex p-4 bg-card  w-full h-fit fixed z-20 items-center justify-between border-b border-input">
              <div className="flex justify-between w-full max-w-6xl px-6 mx-auto relative">
                <div className=" w-fit gap-4 items-center flex justify-between">
                  <Button disabled={isCreating || !form.formState.isDirty} size="sm" type="submit">
                    {isCreating && <Spinner className="mr-2 text-primary-foreground h-3" />}
                    {isCreating ? 'Creating...' : 'Create'}
                  </Button>

                  <FormField
                    control={form.control}
                    name="published"
                    render={({ field }) => (
                      <FormItem className="flex items-center gap-x-2">
                        <div className="flex items-center space-x-2">
                          <Switch id="published" className="data-[state=checked]:bg-brand" checked={field.value} onClick={_ => field.onChange(!field.value)} />
                          <Label htmlFor="published">Publish?</Label>
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex gap-x-1 items-center absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                  <h3 className="text-lg font-medium flex items-center">
                    <Building2 className="h-6 w-6 mr-3" />
                    Add new venue
                  </h3>
                </div>
              </div>
            </div>

            <div className="bg-zinc-100 p-6 m-0  space-y-8 ">
              <Card className="p-6 w-full my-2 max-w-6xl mt-24 mx-auto">
                <h3 className="text-lg  font-medium">Venue details</h3>
                <Separator className="my-4" />
                <div className="flex gap-10 w-full md:flex-row flex-col-reverse">
                  <div className="space-y-8 max-w-lg w-full">
                    <FormField
                      control={form.control}
                      name="venueName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Venue name</FormLabel>
                          <FormControl>
                            <Input placeholder="Venue name.." {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="locationId"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Location</FormLabel>
                          <FormControl>
                            <Combobox
                              placeholder="Select location.."
                              options={locationLeafs?.map(location => ({ label: location.locationName, value: location.locationId })) ?? []}
                              value={field.value?.toString()}
                              onChange={(value: string) => form.setValue('locationId', parseInt(value))}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="address"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Address</FormLabel>
                          <FormControl>
                            <Input placeholder="Address.." {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="w-full h-72 border border-input rounded-md overflow-hidden ">
                    <GoogleMap address={form.watch('address') ?? ''} />
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem className="mt-6">
                      <FormLabel>About the venue</FormLabel>
                      <FormControl>
                        <TinyMCE onChange={(value: string) => field.onChange(value)} value={field.value ?? ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator className="my-4" />
                <FormField
                  control={form.control}
                  name="venueExtras"
                  render={({ field }) => (
                    <FormItem>
                      <h3 className="text-lg  font-medium">Image gallery</h3>
                      <FormDescription>Upload images of the venue</FormDescription>
                      <div className="h-1" />
                      <Separator />
                      <FormControl>
                        <ImageGallery
                          editMode
                          onChange={(value: any) => {
                            field.onChange([...(field.value?.filter(venueExtra => !value.some((image: typeof venueExtra) => image.name === venueExtra.name)) ?? []), ...value]);
                          }}
                          images={field.value as any}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator className="my-4" />
                <div>
                  <div className="mb-2">
                    <h3 className="text-lg font-medium">Other information</h3>
                    <FormDescription>Add details about the venue. This will help you keep track of the venue's capacity and facilities.</FormDescription>
                  </div>
                  <Separator className="my-4" />
                  <div>
                    {venueDetails.map((detail, index) => (
                      <Accordion className="mt-[0_!important]" key={index} type="single" collapsible defaultValue={index === 0 ? `item-${detail.title}` : ''}>
                        <AccordionItem value={`item-${detail.title}`}>
                          <AccordionTrigger>
                            <h3 className="text-sm font-medium flex items-center gap-4">
                              <span className="p-2 bg-sky-100 text-sky-700 flex items-center justify-center rounded-full">{detail.icon}</span>

                              {detail.title}
                            </h3>
                          </AccordionTrigger>
                          <AccordionContent className="pl-1">
                            <div className="grid grid-cols-2 gap-4 w-1/2">
                              {Object.entries(detail.children).map(([key, value]) => (
                                <FormField
                                  key={key}
                                  control={form.control}
                                  name={key as any}
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>{value}</FormLabel>
                                      <FormControl>
                                        <Input type="number" {...field} onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(parseInt(e.target.value))} />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              ))}
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    ))}
                  </div>
                </div>

                <Accordion className="mt-[0_!important]" type="single" collapsible>
                  <AccordionItem value={`item-links`}>
                    <AccordionTrigger>
                      <h3 className="text-sm font-medium flex items-center gap-4">
                        <span className="p-2 bg-sky-100 text-sky-700 flex items-center justify-center rounded-full">
                          <Link className="h-4 w-4" />
                        </span>
                        Links
                      </h3>
                    </AccordionTrigger>
                    <AccordionContent className="pl-1">
                      <div className="grid grid-cols-2 gap-4 p-1 w-1/2">
                        {links.map(link => (
                          <FormField
                            control={form.control}
                            name="venueExtras"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>{link} link</FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder={`${link}...`}
                                    value={field?.value ? field?.value?.find(venueExtra => venueExtra.name === link)?.value : ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      field.onChange([
                                        ...(field.value?.filter(venueExtra => venueExtra.name !== link) ?? []),
                                        {
                                          name: link,
                                          value: e.target.value,
                                          type: 'link',
                                        },
                                      ]);
                                    }}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
                {/* <div className="mt-4">
                  <Button disabled={isCreating || !form.formState.isDirty} size="sm" type="submit">
                    {isCreating && <Spinner className="mr-2 text-primary-foreground h-3" />}
                    {isCreating ? 'Creating...' : 'Save'}
                  </Button>
                </div> */}
              </Card>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
