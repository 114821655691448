import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { User } from '../types';
import { useToast } from '@/components/ui/use-toast';

export type UpdateUserDTO = {
  userId: number;
  firstName: string;
  userName: string;
  lastName: string;
  title: string;
  organization: string;
};

export const updateUser = (data: UpdateUserDTO): Promise<User> => {
  return axios.post(`/user/CreateOrUpdate`, data);
};

type UseUpdateThemeOptions = {
  config?: MutationConfig<typeof updateUser>;
};

export const useUpdateUser = ({ config }: UseUpdateThemeOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (updatingUser: any) => {
      await queryClient.cancelQueries(['user', updatingUser?.userId]);

      const previousUser = queryClient.getQueryData<User>(['user', updatingUser?.userId]);

      queryClient.setQueryData(['user', updatingUser?.userId], {
        ...previousUser,
        ...updatingUser,
        id: updatingUser.userId,
      });

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData(['user', context.previousUser.id], context.previousUser);
      }

      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
      });
    },
    onSuccess: (data: User) => {
      queryClient.refetchQueries(['user', data.userId]);
      queryClient.refetchQueries(['users']);

      toast({
        variant: 'success',
        title: `User ${data.firstName} updated successfully!`,
      });
    },
    ...config,
    mutationFn: updateUser,
  });
};
