import { forwardRef, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './sv_SE';

interface ITextEditorProps {
  value: string;
  onChange: (value: string) => void;
}

export default forwardRef(({ value, onChange }: ITextEditorProps, _: any) => {
  const editorRef = useRef<any>(null);

  return (
    <Editor
      onInit={(_: any, editor: any) => (editorRef.current = editor)}
      value={value}
      onEditorChange={() => onChange(editorRef.current.getContent())}
      init={{
        width: '100%',
        height: '500px',
        menubar: false,
        inline: false,
        language: 'sv_SE',
        toolbar_mode: 'sliding',
        font_formats:
          "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; ui-sans-serif=ui-sans-serif, system-ui, sans-serif; Apple Color Emoji='Apple Color Emoji'; Segoe UI Emoji='Segoe UI Emoji'; Segoe UI Symbol='Segoe UI Symbol'; Noto Color Emoji='Noto Color Emoji'",
        content_style:
          "body { font-family: 'ui-sans-serif', system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; }",
        plugins: ['advlist', 'lists', 'table', 'code'],
        toolbar: 'code | undo redo | blocks | fontselect |' + 'bold italic forecolor underline | alignleft aligncenter ' + 'alignright alignjustify  | list ',
      }}
    />
  );
});
