import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Venue } from '../types';

export type UpdateVenueDTO = {
  venueId: number;
  venueName: string;
  address: string;
  locationId: number;
  exhitorSpaceIndoor?: number;
  exhibitorSpaceOutdoor?: number;
  exhibitorMaxExhibitors?: number;
  exhibitorMaxVisitors?: number;
  exhibitorHalls?: number;
  exhibitorSpaceLargestHall?: number;
  conferenceRooms?: number;
  conferenceLargestRoom?: number;
  conferenceMaxDelegates?: number;
  onsiteHotelRooms?: number;
  offsiteHotelRooms3star?: number;
  offsiteHotelRooms4star?: number;
  offsiteHotelRooms5star?: number;
  parkingLots?: number;
  restaurantTotalSeats?: number;
  restaurantCount?: number;
  restaurantLargestSeat?: number;
  mainImage?: string;
  description?: string;
  published?: boolean;
  venueGroupId?: number;
};

export const updateVenue = (data: UpdateVenueDTO): Promise<Venue> => {
  return axios.put(`/venue`, {
    ...data,
  });
};

type UseUpdateVenueDTO = {
  venueId: number;
  config?: MutationConfig<typeof updateVenue>;
};

export const useUpdateVenue = ({ config, venueId }: UseUpdateVenueDTO) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async newVenue => {
      await queryClient.cancelQueries(['venue', venueId]);

      const previousVenues = queryClient.getQueryData<Venue>(['venue', venueId]);

      queryClient.setQueryData(['venue', venueId], newVenue);

      return { previousVenues };
    },
    onError: (_, __, context: any) => {
      if (context?.previousVenue) {
        queryClient.setQueryData(['venue', venueId], context.previousVenue);
      }
      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['venue', venueId]);
      queryClient.refetchQueries(['venue', venueId]);
    },
    ...config,
    mutationFn: (data: any) =>
      updateVenue({
        ...data,
      }),
  });
};
