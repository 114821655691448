import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Service } from '../types'; // Assuming you've updated the type name here

export type UpsertServiceDTO = {
  serviceId?: number;
  name: string;
  type: string;
};

export const upsertService = (data: UpsertServiceDTO): Promise<Service> => {
  return axios.post(`/service`, {
    ...data,
  });
};

type UseUpdateServiceDTO = {
  config?: MutationConfig<typeof upsertService>;
};

export const useUpsertService = ({ config }: UseUpdateServiceDTO) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
      });
    },
    ...config,
    mutationFn: (data: any) =>
      upsertService({
        ...data,
      }),
  });
};
