import { create } from 'zustand';

type SearchQueryStore = {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
};

export const useSearchQueryStore = create<SearchQueryStore>(set => ({
  searchQuery: '',
  setSearchQuery: (searchQuery: string) => set({ searchQuery }),
}));
