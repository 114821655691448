import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Filters } from '../types';

const exhibitorFilters = [
  {
    title: 'Exhibition space indoors, sqm gross',
    value: 'exhibitorSpaceIndoor',
    options: [
      { value: 'empty', label: 'Select option...' },
      { value: '1TO1000', label: 'Up to 1000 sqm gross' },
      { value: 'GREATEROREQUAL1000', label: '1000 or more sqm gross' },
      { value: 'GREATEROREQUAL2500', label: '2500 or more sqm gross' },
      { value: 'GREATEROREQUAL5000', label: '5000 or more sqm gross' },
      { value: 'GREATEROREQUAL10000', label: '10000 or more sqm gross' },
      { value: 'GREATEROREQUAL50000', label: '50000 or more sqm gross' },
    ],
  },
  {
    title: 'Exhibition space outdoors, sqm gross',
    value: 'exhibitorSpaceOutdoor',
    options: [
      { value: 'empty', label: 'Select option...' },

      { value: '1TO1000', label: 'Up to 1000 sqm gross' },
      { value: 'GREATEROREQUAL1000', label: '1000 or more sqm gross' },
      { value: 'GREATEROREQUAL2500', label: '2500 or more sqm gross' },
      { value: 'GREATEROREQUAL5000', label: '5000 or more sqm gross' },
      { value: 'GREATEROREQUAL10000', label: '10000 or more sqm gross' },
      { value: 'GREATEROREQUAL50000', label: '50000 or more sqm gross' },
    ],
  },
  {
    title: 'Number of exhibitors',
    value: 'exhibitorMaxExhibitors',
    options: [
      { value: 'empty', label: 'Select option...' },
      { value: '1TO100', label: 'Up to 100 exhibitors' },
      { value: '100TO300', label: '100-300 exhibitors' },
      { value: '300TO500', label: '300-500 exhibitors' },
      { value: '500TO800', label: '500-800 exhibitors' },
      { value: '800TO1500', label: '800-1500 exhibitors' },
      { value: 'GREATEROREQUAL1500', label: '1500 or more exhibitors' },
    ],
  },
  {
    title: 'Number of visitors',
    value: 'exhibitorMaxVisitors',
    options: [
      { value: 'empty', label: 'Select option...' },
      { value: '1TO1000', label: 'Up to 1000 visitors' },
      { value: '1000TO2500', label: '1000-2500 visitors' },
      { value: '2500TO5000', label: '2500-5000 visitors' },
      { value: '5000TO10000', label: '5000-10000 visitors' },
      { value: '10000TO30000', label: '10000-30000 visitors' },
      { value: 'GREATEROREQUAL30000', label: '30000 or more visitors' },
    ],
  },
];

interface ExhibitionFiltersProps {
  filters: Filters;
  onChange: (key: string, value: string) => void;
  disabled?: boolean;
}

export function ExhibitionFilters({ filters, onChange }: ExhibitionFiltersProps) {
  return (
    <div className="space-y-2">
      <div>
        {exhibitorFilters.map(filter => (
          <div key={filter.value} className="flex flex-col items-start space-y-2 mb-4">
            <label className=" text-sm font-semibold">{filter.title}</label>
            <Select value={(filters?.[filter.value as keyof typeof filters] as string) ?? 'empty'} onValueChange={value => onChange(filter.value, value)}>
              <SelectTrigger >
                <SelectValue  placeholder="Select a fruit" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {filter.options.map(option => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        ))}
      </div>
    </div>
  );
}
