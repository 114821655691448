import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';

export const approveReview = (venueId: number): Promise<void> => {
  return axios.put(`/venue/ApproveReview/${venueId}`);
};

type UseApproveReviewOptions = {
  config?: MutationConfig<typeof approveReview>;
};

export const useApproveReview = ({ config }: UseApproveReviewOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: 'Something went wrong. Please try again.',
      });
    },
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Review approved!',
      });
    },
    ...config,
    mutationFn: (venueId: number) => approveReview(venueId),
  });
};
