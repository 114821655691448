import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Venue } from '../types';

export const getVenues = (venueGroupId?: number | null): Promise<Venue[]> => {
  const searchParams = venueGroupId ? `venueGroupId=${venueGroupId}` : '';
  return axios.get(`/Venue/venueGroup?${searchParams}`);
};

type QueryFnType = typeof getVenues;

type UseVenuesOptions = {
  venueGroupId?: number | null;
  config?: QueryConfig<QueryFnType>;
};

export const useVenueGroups = ({ config, venueGroupId }: UseVenuesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['venues', venueGroupId],
    queryFn: () => getVenues(venueGroupId),
    ...config,
  });
};
