import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Service } from '../types';

export const getServices = (): Promise<Service[]> => {
  return axios.get(`/Service/GetAllServices`);
};

type QueryFnType = typeof getServices;

type UseServiceOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useServices = ({ config }: UseServiceOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['services'],
    queryFn: () => getServices(),
    ...config,
  });
};
