import { Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import Layout from '@/components/layout';
// import { lazyImport } from '@/utils/lazyImport';

// const { Search } = lazyImport(() => import('@/features/venue/routes/Search'), 'Search');
// const { Venue } = lazyImport(() => import('@/features/venue/routes/Venue'), 'Venue');
// const { UserEdit } = lazyImport(() => import('@/features/user/routes/UserEdit'), 'UserEdit');

import { Search } from '@/features/venue';
import { Venue } from '@/features/venue';
import { UserEdit } from '@/features/user/routes/UserEdit';

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<div className="h-full w-full flex items-center justify-center"></div>}>
        <Outlet />{' '}
      </Suspense>
    </Layout>
  );
};

export const userRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '*', element: <Search /> },
      { path: '/', element: <Search /> },
      { path: '/venues/search', element: <Search /> },
      { path: '/venues/:id', element: <Venue /> },
      { path: '/users/edit/:id', element: <UserEdit /> },
    ],
  },
];
