import { z } from 'zod';
import { Location } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Combobox } from '@/components/ui/combobox';
import { Button } from '@/components/ui/button';

interface UpsertLocationDialogProps {
  location: Location | Partial<Location>;
  locations: Location[];
  onClose: () => void;
  onSubmit: (
    location:
      | Location
      | {
          locationId?: number;
          locationName: string;
          parentLocationId: number;
        }
  ) => void;
}

const formSchema = z.object({
  locationName: z.string(),
  parentLocationId: z.number(),
});

export function UpsertLocationDialog({ location, locations, onSubmit, onClose }: UpsertLocationDialogProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      locationName: location.locationName,
      parentLocationId: location.parentLocationId,
    },
  });

  function handleSubmit(data: z.infer<typeof formSchema>) {
    onSubmit({
      ...location,
      ...data,
    });
  }

  return (
    <Dialog open onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{location.locationId ? 'Edit' : 'Create'} Location</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-8" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="locationName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Location name</FormLabel>
                  <FormControl>
                    <Input placeholder="Venue name.." {...field} />
                  </FormControl>
                  <FormDescription>Enter the name of the location, example: Tokyo</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="parentLocationId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Is in parent loction</FormLabel>
                  <FormControl>
                    <Combobox
                      placeholder="Select location.."
                      options={locations?.map(location => ({ label: location.locationName, value: location.locationId })) ?? []}
                      value={field.value ? field.value.toString() : null}
                      onChange={(value: string) => field.onChange(parseInt(value, 10) || null)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">{location.locationId ? 'Update' : 'Create'}</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
