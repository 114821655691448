import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

import { useToast } from '@/components/ui/use-toast';
import { Quote } from '../types';

export type CreateQuouteDTO = {
  data: Quote;
};

export const createQuote = ({ data }: CreateQuouteDTO): Promise<void> => {
  return axios.post(`/api/offer`, {
    ...data,
  });
};

type UseCreateQuouteDTO = {
  config?: MutationConfig<typeof createQuote>;
};

export const useCreateQuote = ({ config }: UseCreateQuouteDTO) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: 'Something went wrong. Please try again.',
      });
    },
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Quote sent!',
      });
    },
    ...config,
    mutationFn: (data: any) =>
      createQuote({
        ...data,
      }),
  });
};
