import { Button } from '@/components/ui/button';
import { useApproveReview } from '../api/approveReview';

interface ApproveReviewButtonProps {
  venueId: number;
}

export function ApproveReviewButton({ venueId }: ApproveReviewButtonProps) {
  const { mutateAsync: handleApproveReview } = useApproveReview({});

  return <Button variant="outline" onClick={() => handleApproveReview(venueId)}>Approve?</Button>;
}
