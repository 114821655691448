import { Button } from '@/components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import { useLocations } from '@/features/location/api/getLocations';
import { Combobox } from '@/components/ui/combobox';
import TinyMCE from '@/components/tinyMCE';
import { GoogleMap } from '@/components/GoogleMap/GoogleMap';
import { ImageGallery } from '../components/ImageGallery';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Building2, EyeIcon, Link, Trash } from 'lucide-react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { useUpdateVenue } from '../api/updateVenue';
import { useVenue } from '../api/getVenue';
import { useDeleteVenue } from '../api/deleteVenue';
import { DeleteButton } from '@/components/DeleteButton';
import { useToast } from '@/components/ui/use-toast';
import { useUpsertVenueExtra } from '@/features/venueExtra/api/upsertVenueExtra';
import uuid4 from 'uuid4';
import { uploadFile } from '@/features/storage/api/uploadFile';
import { queryClient } from '@/lib/react-query';
import { links, venueDetails } from '../constants';
import { VenueExtra } from '@/features/venueExtra/types';
import { useMemo, useState } from 'react';
import { useVenueExtra } from '@/features/venueExtra/api/getVenueExtra';
import { useApproveReview } from '../api/approveReview';
import storage from '@/utils/storage';
import { getLocationLeaf } from '@/utils/getLocationLeaf';
import { Location } from '@/features/location/types';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

const formSchema = z.object({
  venueName: z.string(),
  address: z.string(),
  venueId: z.number(),
  locationId: z.number(),
  exhibitorSpaceIndoor: z.number().optional(),
  exhibitorSpaceOutdoor: z.number().optional(),
  exhibitorMaxExhibitors: z.number().optional(),
  exhibitorMaxVisitors: z.number().optional(),
  exhibitorHalls: z.number().optional(),
  exhibitorSpaceLargestHall: z.number().optional(),
  conferenceRooms: z.number().optional(),
  conferenceLargestRoom: z.number().optional(),
  conferenceMaxDelegates: z.number().optional(),
  onsiteHotelRooms: z.number().optional(),
  offsiteHotelRooms3star: z.number().optional(),
  offsiteHotelRooms4star: z.number().optional(),
  offsiteHotelRooms5star: z.number().optional(),
  parkingLots: z.number().optional(),
  restaurantTotalSeats: z.number().optional(),
  restaurantCount: z.number().optional(),
  restaurantLargestSeat: z.number().optional(),
  description: z.string().optional(),
  published: z.nullable(z.boolean()).optional(),
  venueGroupId: z.nullable(z.number()),
  venueExtra: z
    .array(
      z.object({
        venueExtraId: z.number().optional(),
        venueId: z.any().optional(),
        type: z.union([z.literal('image'), z.literal('link'), z.undefined()]),
        name: z.string(),
        value: z.string().optional(),
        file: z.union([z.instanceof(File), z.instanceof(ArrayBuffer), z.null(), z.string(), z.any()]).optional(),
      })
    )
    .optional(),
});

export function VenueEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();

  const { admin, venueGroupId } = storage.getUser();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { mutateAsync: upsertVenueExtra } = useUpsertVenueExtra({});
  const { mutateAsync: updateVenue } = useUpdateVenue({
    venueId: Number(id),
  });

  const { mutateAsync: approveReview } = useApproveReview({});
  const { mutateAsync: deleteVenue } = useDeleteVenue({
    config: {
      onSuccess: () => {
        toast({
          title: 'Venue deleted successfully!',
          variant: 'success',
        });
        navigate('/venues');
      },
    },
  });

  const { data: locations } = useLocations({});
  const { data: venueExtra } = useVenueExtra({
    venueId: Number(id),
  });
  const { data: venue, isLoading } = useVenue({
    venueId: Number(id),
  });

  const locationLeafs: Location[] = useMemo(
    () =>
      locations?.reduce((acc, location): any => {
        if (!location.parentLocationId) return acc;
        return [...acc, getLocationLeaf(location, locations)];
      }, []) ?? [],
    [locations]
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      venueName: '',
    },
    values: {
      venueName: venue?.venueName ?? '',
      address: venue?.address ?? '',
      venueId: venue?.venueId ?? 0,
      locationId: venue?.locationId ?? 0,
      exhibitorSpaceIndoor: venue?.exhibitorSpaceIndoor,
      exhibitorSpaceOutdoor: venue?.exhibitorSpaceOutdoor,
      exhibitorMaxExhibitors: venue?.exhibitorMaxExhibitors,
      exhibitorMaxVisitors: venue?.exhibitorMaxVisitors,
      exhibitorHalls: venue?.exhibitorHalls,
      exhibitorSpaceLargestHall: venue?.exhibitorSpaceLargestHall,
      conferenceRooms: venue?.conferenceRooms,
      conferenceLargestRoom: venue?.conferenceLargestRoom,
      conferenceMaxDelegates: venue?.conferenceMaxDelegates,
      onsiteHotelRooms: venue?.onsiteHotelRooms,
      offsiteHotelRooms3star: venue?.offsiteHotelRooms3star,
      offsiteHotelRooms4star: venue?.offsiteHotelRooms4star,
      offsiteHotelRooms5star: venue?.offsiteHotelRooms5star,
      parkingLots: venue?.parkingLots,
      restaurantTotalSeats: venue?.restaurantTotalSeats,
      restaurantCount: venue?.restaurantCount,
      restaurantLargestSeat: venue?.restaurantLargestSeat,
      description: venue?.description ?? '',
      published: venue?.published,
      venueExtra: venueExtra ?? [],
      venueGroupId: venue?.venueGroupId ?? null,
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIsUpdating(true);
      // Update venue
      const updatedVenue = await updateVenue(values as any);

      toast({
        variant: 'success',
        title: 'Venue updated successfully!',
      });

      const newVenueExtras = [...(values.venueExtra ?? [])]?.map((venueExtra: any) => ({ ...venueExtra, venueId: updatedVenue.venueId }));

      for (const venueExtra of newVenueExtras ?? []) {
        const previousVenueExtra = venue?.venueExtra?.find(ve => ve.name === venueExtra.name);
        const hasChanged = previousVenueExtra?.value !== venueExtra.value;

        if (hasChanged || !previousVenueExtra) {
          //Upload image if it's a new image
          if (venueExtra.type === 'image' && venueExtra.file) {
            try {
              const fileDto = {
                id: uuid4(),
                name: venueExtra.name + '.jpg',
                size: 0,
                type: 'image',
                srcSet: 'string',
                url: 'string',
                created: new Date(),
                file: venueExtra.file,
              };

              const file: any = await uploadFile(fileDto, updatedVenue.venueId);
              venueExtra.value = (file.url as string) ?? '';
            } catch (error) {
              toast({
                variant: 'destructive',
                title: 'Something went wrong during image upload!',
              });
            }
          }
          await upsertVenueExtra(venueExtra);
        }
      }

      queryClient.invalidateQueries(['venueExtra', updatedVenue.venueId]);
      queryClient.refetchQueries(['venueExtra', updatedVenue.venueId]);
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
      });
    } finally {
      setIsUpdating(false);
    }
  }

  function handleImageChange(value: VenueExtra[] | Partial<VenueExtra>[]) {
    const venueExtras = form.watch('venueExtra')?.filter(venueExtra => venueExtra.type !== 'image') ?? [];
    const imageExtras = value.map(venueExtra => {
      const previousVenueExtra = venue?.venueExtra?.find(ve => ve.name === venueExtra.name);
      return { ...previousVenueExtra, ...venueExtra };
    });

    return [...venueExtras, ...imageExtras];
  }

  function handleLinkChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;
    const venueExtras = form.watch('venueExtra') ?? [];
    const index = venueExtras.findIndex(venueExtra => venueExtra.type === 'link' && venueExtra.name === name);
    if (index !== -1) {
      venueExtras[index].value = value;
      return venueExtras;
    } else {
      return [...venueExtras, { name, value, type: 'link' }];
    }
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex p-4 bg-card  w-full h-fit items-center fixed z-20 justify-between border-b border-input">
            <div className="flex justify-between w-full max-w-6xl px-6 mx-auto relative">
              <div className=" w-fit gap-4 items-center flex justify-between">
                <Button disabled={isLoading || !form.formState.isDirty} size="sm" type="submit">
                  {isUpdating && <Spinner className="mr-2 text-primary-foreground h-3" />}
                  {isUpdating ? 'Updating...' : 'Save'}
                </Button>
                {venue?.awaitingReview && admin && !venueGroupId && venue.published && (
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Button disabled={form.formState.isDirty} variant="outline" onClick={() => approveReview(venue.venueId)}>
                        Approve?
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{form.formState.isDirty ? 'Save changes before approving' : 'Approve draft'}</TooltipContent>
                  </Tooltip>
                )}
                <FormField
                  control={form.control}
                  name="published"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-x-2">
                      <div className="flex items-center space-x-2">
                        <Switch id="published" className="data-[state=checked]:bg-brand" checked={field.value ?? false} onClick={_ => field.onChange(!field.value)} />
                        <Label htmlFor="published">Publish?</Label>
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex gap-x-1 items-center absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                <h3 className="text-lg font-medium flex items-center">
                  <Building2 className="h-6 w-6 mr-3" />
                  {venue?.venueName ?? ''}
                </h3>
              </div>
              <div className="flex gap-x-2">
                <Tooltip>
                  <TooltipTrigger type="button">
                    <Button disabled={form.formState.isDirty} variant="outline" onClick={() => navigate('/venues/' + venue?.venueId)}>
                      <EyeIcon className="h-4 w-4 mr-2" />
                      Preview
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{form.formState.isDirty ? 'Save changes before previewing' : 'Preview venue'}</TooltipContent>
                </Tooltip>

                <DeleteButton
                  onDelete={async () => await deleteVenue({ venueId: Number(id) })}
                  ButtonComponent={
                    <Button variant="destructive">
                      <Trash className="h-4 w-4 mr-2" />
                      Delete venue
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-zinc-100 p-6 space-y-8 ">
            <Card className="p-6 w-full my-2 max-w-6xl mt-24 mx-auto">
              <h3 className="text-lg  font-medium">Venue details</h3>
              <Separator className="my-4" />
              <div className="flex gap-10 w-full md:flex-row flex-col-reverse">
                <div className="space-y-8 max-w-lg w-full">
                  <FormField
                    control={form.control}
                    name="venueName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Venue name</FormLabel>
                        <FormControl>
                          <Input placeholder="Venue name.." {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="locationId"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Location</FormLabel>
                        <FormControl>
                          <Combobox
                            placeholder="Select location.."
                            options={locationLeafs?.map(location => ({ label: location.locationName, value: location.locationId })) ?? []}
                            value={field.value?.toString()}
                            onChange={(value: string) => field.onChange(parseInt(value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address</FormLabel>
                        <FormControl>
                          <Input placeholder="Address.." {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-full h-72 border border-input rounded-md overflow-hidden ">
                  <GoogleMap address={form.watch('address') ?? ''} />
                </div>
              </div>

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="mt-6">
                    <FormLabel>About the venue</FormLabel>
                    <FormControl>
                      <TinyMCE onChange={(value: string) => field.onChange(value)} value={field.value ?? ''} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Separator className="my-4" />
              <FormField
                control={form.control}
                name="venueExtra"
                render={({ field }) => (
                  <FormItem>
                    <h3 className="text-lg  font-medium">Image gallery</h3>
                    <FormDescription>Upload images of the venue</FormDescription>
                    <div className="h-1" />
                    <Separator />
                    <FormControl>
                      <ImageGallery
                        editMode
                        onChange={(value: Partial<VenueExtra>[]) => field.onChange(handleImageChange(value))}
                        images={field?.value?.filter(venueExtra => venueExtra.type === 'image') ?? []}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Separator className="my-4" />
              <div>
                <div className="mb-2">
                  <h3 className="text-lg font-medium">Other information</h3>
                  <FormDescription>Add details about the venue. This will help you keep track of the venue's capacity and facilities.</FormDescription>
                </div>
                <Separator className="my-4" />
                <div>
                  {venueDetails.map((detail, index) => (
                    <Accordion className="mt-[0_!important]" key={index} type="single" collapsible defaultValue={index === 0 ? `item-${detail.title}` : ''}>
                      <AccordionItem value={`item-${detail.title}`}>
                        <AccordionTrigger>
                          <h3 className="text-sm font-medium flex items-center gap-4">
                            <span className="p-2 bg-sky-100 text-sky-700 flex items-center justify-center rounded-full">{detail.icon}</span>

                            {detail.title}
                          </h3>
                        </AccordionTrigger>
                        <AccordionContent className="pl-1">
                          <div className="grid grid-cols-2 gap-4 w-1/2">
                            {Object.entries(detail.children).map(([key, value]) => (
                              <FormField
                                key={key}
                                control={form.control}
                                name={key as any}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>{value}</FormLabel>
                                    <FormControl>
                                      <Input type="number" {...field} onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(parseInt(e.target.value))} />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            ))}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  ))}
                </div>
              </div>

              <Accordion className="mt-[0_!important]" type="single" collapsible>
                <AccordionItem value={`item-links`}>
                  <AccordionTrigger>
                    <h3 className="text-sm font-medium flex items-center gap-4">
                      <span className="p-2 bg-sky-100 text-sky-700 flex items-center justify-center rounded-full">
                        <Link className="h-4 w-4" />
                      </span>
                      Links
                    </h3>
                  </AccordionTrigger>
                  <AccordionContent className="pl-1">
                    <div className="grid grid-cols-2 gap-4 p-1 w-1/2">
                      {links.map(link => (
                        <FormField
                          control={form.control}
                          name="venueExtra"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{link} link</FormLabel>
                              <FormControl>
                                <Input
                                  placeholder={`${link}...`}
                                  value={field?.value ? field?.value?.find(venueExtra => venueExtra.name === link)?.value : ''}
                                  name={link}
                                  onChange={event => field.onChange(handleLinkChange(event))}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </Card>
          </div>
        </form>
      </Form>
    </div>
  );
}
