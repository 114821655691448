import { Button } from '@/components/ui/button';
import { Combobox } from '@/components/ui/combobox';
import { useLocations } from '@/features/location/api/getLocations';
import { Filter as FilterIcon, Settings2 } from 'lucide-react';
import { useState } from 'react';
import { ExhibitionFilters } from './ExhibitionFilters';
import { Separator } from '@/components/ui/separator';
import { ConferenceFilters } from './ConferenceFilters';
import { HotelFilters } from './HotelFilters';
import { Filters } from '../types';
import { useSearchParams } from 'react-router-dom';
import { Sheet, SheetContent, SheetFooter, SheetTrigger } from '@/components/ui/sheet';
import { useSearchQueryStore } from '@/stores/searchQueryStore';

type Option = {
  value: any;
  label: string;
};

export function FilterMenu() {
  const setSearchQuery = useSearchQueryStore(state => state.setSearchQuery);
  const searchQuery = useSearchQueryStore(state => state.searchQuery);

  const [searchParams, setSearchParams] = useSearchParams(new URLSearchParams(window.location.search) ?? new URLSearchParams(searchQuery));

  const [continents, setContinents] = useState<Option[]>([]);
  const [countries, setCountries] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);

  const [filters, setFilters] = useState<Filters>({
    continent: searchParams.get('continent')
      ? (decodeURIComponent(searchParams.get('continent') as string)
          .split(',')
          .map(Number) as unknown as number[])
      : null,
    country: searchParams.get('country')
      ? (decodeURIComponent(searchParams.get('country') as string)
          .split(',')
          .map(Number) as unknown as number[])
      : null,
    city: searchParams.get('city')
      ? (decodeURIComponent(searchParams.get('city') as string)
          .split(',')
          .map(Number) as unknown as number[])
      : null,
    exhibitorSpaceIndoor: searchParams.get('exhibitorSpaceIndoor') ? decodeURIComponent(searchParams.get('exhibitorSpaceIndoor') as string) : null,
    exhibitorSpaceOutdoor: searchParams.get('exhibitorSpaceOutdoor') ? decodeURIComponent(searchParams.get('exhibitorSpaceOutdoor') as string) : null,
    exhibitorMaxExhibitors: searchParams.get('exhibitorMaxExhibitors') ? decodeURIComponent(searchParams.get('exhibitorMaxExhibitors') as string) : null,
    exhibitorMaxVisitors: searchParams.get('exhibitorMaxVisitors') ? decodeURIComponent(searchParams.get('exhibitorMaxVisitors') as string) : null,
    exhibitorHalls: searchParams.get('exhibitorHalls') ? decodeURIComponent(searchParams.get('exhibitorHalls') as string) : null,
    exhibitorSpaceLargestHall: searchParams.get('exhibitorSpaceLargestHall') ? decodeURIComponent(searchParams.get('exhibitorSpaceLargestHall') as string) : null,
    conferenceRooms: searchParams.get('conferenceRooms') ? decodeURIComponent(searchParams.get('conferenceRooms') as string) : null,
    conferenceLargestRoom: searchParams.get('conferenceLargestRoom') ? decodeURIComponent(searchParams.get('conferenceLargestRoom') as string) : null,
    conferenceMaxDelegates: searchParams.get('conferenceMaxDelegates') ? decodeURIComponent(searchParams.get('conferenceMaxDelegates') as string) : null,
    onsiteHotelRooms: searchParams.get('onsiteHotelRooms') ? decodeURIComponent(searchParams.get('onsiteHotelRooms') as string) : null,
  });

  useLocations({
    config: {
      onSuccess: data => {
        const newContinents = data
          ?.filter(location => location.parentLocationId === 1)
          ?.map(location => ({
            value: [location.locationId, location.parentLocationId, 0],
            label: location.locationName,
          }));

        const newCountries = data
          ?.filter(l => newContinents.map(c => c.value[0]).includes(l.parentLocationId))
          .map(l => ({
            label: l.locationName,
            value: [l.locationId, l.parentLocationId, 1],
          }));

        const newCities = data
          ?.filter(l => newCountries.map(c => c.value[0]).includes(l.parentLocationId))
          .map(l => ({
            label: l.locationName,
            value: [l.locationId, l.parentLocationId, 2],
          }));

        setContinents(newContinents ?? []);
        setCountries(newCountries ?? []);
        setCities(newCities ?? []);
      },
    },
  });

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: prev[key as keyof typeof prev] === value ? null : value }));
  };

  const handleClearFilters = () => {
    setFilters({
      continent: null,
      country: null,
      city: null,
      exhibitorSpaceIndoor: null,
      exhibitorSpaceOutdoor: null,
      exhibitorMaxExhibitors: null,
      exhibitorMaxVisitors: null,
      exhibitorHalls: null,
      exhibitorSpaceLargestHall: null,
      conferenceRooms: null,
      conferenceLargestRoom: null,
      conferenceMaxDelegates: null,
      onsiteHotelRooms: null,
    } as Filters);
    setSearchParams(new URLSearchParams());
    setSearchQuery('');
  };

  const handleApplyFilter = () => {
    const newSearchParams = searchParams;

    Object.entries(filters).forEach(([key, value]: [string, string | number | number[] | null]) => {
      if (newSearchParams.has(key) && (value === null || value === 'empty')) {
        newSearchParams.delete(key);
      } else if (value !== null && value !== 'empty') {
        newSearchParams.set(key, encodeURIComponent(value as string));
      }
    });

    setSearchParams(newSearchParams);
    setSearchQuery(newSearchParams.toString());
  };

  const hasActiveFilters = Object.values(filters).some(value => value !== null && value !== 'empty');

  return (
    <Sheet>
      <SheetTrigger>
        <Button variant="ghost" className="relative rounded-full border-input border p-3">
          {hasActiveFilters && <span className="absolute -top-0 right-0 h-2 w-2 bg-brand rounded-full" />}
          <Settings2 className="h-4 w-4" />
        </Button>
      </SheetTrigger>
      <SheetContent className="space-y-4 pb-4 overflow-y-auto">
        <h4 className="font-semibold flex gap-x-2 items-center">
          <FilterIcon className="h-4 w-4" />
          Filters
        </h4>
        <Separator />
        <div className="flex flex-col space-y-4">
          <Combobox placeholder="Select continent..." options={continents ?? []} value={filters.continent} onChange={(value: string) => handleFilterChange('continent', value)} />
          <Combobox
            placeholder="Select country..."
            disabled={!filters.continent}
            options={countries?.filter(country => country.value[1] === filters.continent?.[0]) ?? []}
            value={filters.country}
            onChange={(value: string) => handleFilterChange('country', value)}
          />
          <Combobox
            placeholder="Select city..."
            disabled={!filters.country}
            options={cities?.filter(city => city.value[1] === filters.country?.[0]) ?? []}
            value={filters.city}
            onChange={(value: string) => handleFilterChange('city', value)}
          />
        </div>
        <Separator />
        <ExhibitionFilters filters={filters} onChange={handleFilterChange} />
        <Separator />
        <ConferenceFilters filters={filters} onChange={handleFilterChange} />
        <Separator />
        <HotelFilters filters={filters} onChange={handleFilterChange} />
        <Button className="w-full" onClick={handleApplyFilter}>
          Apply filter
        </Button>
        <Button variant="outline" className="w-full" onClick={handleClearFilters}>
          Clear filter
        </Button>
      </SheetContent>
      {hasActiveFilters && (
        <SheetFooter className="items-center hidden md:flex">
          <Button variant="ghost" onClick={handleClearFilters}>
            Clear filter
          </Button>
        </SheetFooter>
      )}
    </Sheet>
  );
}
