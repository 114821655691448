import * as z from 'zod';

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { login } from '@/features/auth/api/login';
import { useToast } from '../../../components/ui/use-toast';
import { useState } from 'react';
import { Spinner } from '../../../components/ui/spinner';

const formSchema = z.object({
  userName: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
  rememberMe: z.boolean(),
  returnUrl: z.string(),
  failedLogin: z.boolean(),
});

export const LoginForm = () => {
  const [isLoading, setIloading] = useState(false);

  const { toast } = useToast();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userName: '',
      password: '',
      rememberMe: false,
      returnUrl: '/',
      failedLogin: false,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIloading(true);
      await login(values);

      if (window.location.pathname === '/login') {
        window.location.href = '/';
      } else {
        window.location.href = window.location.href;
      }
    } catch (error) {
      toast({
        title: 'Incorrect username or password!',
        variant: 'destructive',
        duration: 9000,
      });
    } finally {
      setIloading(false);
    }
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="userName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input placeholder="example@gmail.com" autoComplete="username" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input type="password" placeholder="" autoComplete="current-password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={isLoading} size="sm" className="w-full flex justify-center items-center" type="submit">
            <span className="flex items-center justify-center">
              {isLoading && <Spinner className="mr-2 text-primary-foreground h-3" />}
              {isLoading ? 'Logging in' : 'Login'}
            </span>
          </Button>
        </form>
      </Form>
    </div>
  );
};
