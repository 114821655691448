import * as React from 'react';
// import { ErrorBoundary } from "react-error-boundary";

import { QueryClientProvider } from 'react-query';
import { queryClient } from '@/lib/react-query';
import { ThemeProvider } from './theme';
import { TooltipProvider } from '@/components/ui/tooltip';

// const ErrorFallback = () => {
//   return (
//     <div className="text-red-500 w-screen h-screen flex flex-col justify-center items-center" role="alert">
//       <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
//       <Button size="sm" className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
//         Refresh
//       </Button>
//     </div>
//   );
// };

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <TooltipProvider>
      <ThemeProvider>
        <QueryClientProvider client={queryClient} contextSharing>
          {children}
        </QueryClientProvider>
      </ThemeProvider>
    </TooltipProvider>
  );
};
