import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Venue } from '../types';

export const getVenue = async ({ venueId }: { venueId: number }): Promise<Venue> => {
  return axios.get(`/venue/${venueId}`);
};

type QueryFnType = typeof getVenue;

type UseVenueOptions = {
  venueId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useVenue = ({ venueId, config }: UseVenueOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['venue', venueId],
    queryFn: () => getVenue({ venueId }),
  });
};
