import { Card } from '@/components/ui/card';
import { Location } from '../types';
import { DeleteButton } from '@/components/DeleteButton';
import { Button } from '@/components/ui/button';
import { Trash2Icon } from 'lucide-react';

interface LocationListItemProps {
  location: Location & { parentLocationName?: string };
  onDelete: (location: Location) => void;
  onEdit: (location: Location) => void;
}

export function LocationListItem({ location, onDelete, onEdit }: LocationListItemProps) {
  return (
    <Card className="max-w-6xl w-full mx-auto p-4 flex items-center justify-between">
      <div className="flex items-center gap-x-1">
        <h2 className="font-semibold">{location.locationName}</h2>
        in
        <p>{location.parentLocationName}</p>
      </div>
      <div className="flex gap-x-2">
        <Button variant="outline" onClick={() => onEdit(location)}>Edit</Button>
        <DeleteButton
          onDelete={() => onDelete(location)}
          ButtonComponent={
            <Button variant="destructive">
              <Trash2Icon className="h-4 w-4" />
            </Button>
          }
        />
      </div>
    </Card>
  );
}
