import { Link, useParams } from 'react-router-dom';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { BadgeCheck, Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';
import { restorePassword } from '../api/restorePassword';
import { useToast } from '@/components/ui/use-toast';
import { Card } from '@/components/ui/card';
import { Venue4YouLogo } from '@/components/Venue4YouLogo';

const passwordSchema = z
  .string()
  .min(6, { message: 'The password must be at least 6 characters long' })
  // .regex(/[A-Z]/, { message: 'The password must contain at least one uppercase letter' })
  // .regex(/[a-z]/, { message: 'The password must contain at least one lowercase letter' })
  // .regex(/[0-9]/, { message: 'The password must contain at least one number' });
// .regex(/[^A-Za-z0-9]/, { message: 'Lösenordet måste innehålla minst ett specialtecken' });

const formSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export function RestorePassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isRestored, setIsRestored] = useState(false);

  const { toast } = useToast();

  const { id } = useParams();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    if (!id) return;
    try {
      await restorePassword({ newPassword: values.password, resetToken: id as string });
      setIsRestored(true);
    } catch (error: any) {
      toast({
        variant: 'destructive',
        title: error?.response?.data || 'Something went wrong!',
      });
    }
  }

  if (isRestored) {
    return (
      <div className="bg-zinc-100  h-screen w-full flex flex-col items-center justify-center">
        {window.location === window.parent.location && (
          <div className="w-80 mx-auto mb-4">
            <Venue4YouLogo />
          </div>
        )}
        <div className="space-y-4 text-center">
          <div className="space-y-2  flex flex-col items-center">
            <BadgeCheck size={48} />
            <h3 className="text-lg font-medium">Password has been reset!</h3>
          </div>
          <div>
            <Link to="/login">
              <Button>Logga in</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-zinc-100 h-screen w-full flex flex-col items-center justify-center">
      {window.location === window.parent.location && (
        <div className="w-80 mx-auto mb-4">
          <Venue4YouLogo />
        </div>
      )}
      <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
        <h3 className="text-lg font-medium">Reset password</h3>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input {...field} type={passwordVisible ? 'text' : 'password'} className="pr-8" />
                      <Button onClick={() => setPasswordVisible(!passwordVisible)} variant="ghost" size="xs" type="button" className="absolute right-1 top-1/2 -translate-y-1/2 ">
                        {passwordVisible ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Confirm password</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input {...field} type={confirmPasswordVisible ? 'text' : 'password'} className="pr-8" />
                      <Button
                        type="button"
                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                        variant="ghost"
                        size="xs"
                        className="absolute right-1 top-1/2 -translate-y-1/2"
                      >
                        {confirmPasswordVisible ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">Save</Button>
          </form>
        </Form>
      </Card>
    </div>
  );
}
