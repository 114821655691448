import * as z from 'zod';

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '../../../components/ui/use-toast';
import { useState } from 'react';
import { Spinner } from '../../../components/ui/spinner';
import { register } from '../api/register';
import { Card, CardDescription, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Venue4YouLogo } from '@/components/Venue4YouLogo';
import { Link, useNavigate } from 'react-router-dom';
import { useLocationStore } from '@/stores/locationStore';

const formSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  //   country: z.number().refine(value => !!value, { message: 'Country is required' }),
  userName: z.string().min(1, 'Username is required'),
  title: z.string().min(1, 'Title is required'),
  password: z.string().min(8, 'Password must be at least 8 characters long'),
  organization: z.string().min(1, 'Organization is required'),
});

export function Register() {
  const [isLoading, setIloading] = useState(false);
  const parentLocation = useLocationStore(state => state.parentLocation);

  const navigate = useNavigate();

  const { toast } = useToast();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userName: '',
      password: '',
      firstName: '',
      lastName: '',
      title: '',
      organization: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIloading(true);

      await register({ ...values, path: parentLocation ?? '' });

      toast({
        title: 'An email has been sent to you to verify your account. Please check your email!',
        variant: 'success',
        duration: 9000,
      });

      navigate('/login');
    } catch (error: any) {
      const message = error?.response?.data?.message || 'Incorrect username or password!';
      toast({
        title: message,
        variant: 'destructive',
        duration: 9000,
      });
    } finally {
      setIloading(false);
    }
  }

  return (
    <div className="bg-zinc-100 overflow-y-auto w-full">
      <div className="flex flex-col w-full justify-center items-center py-6">
        <div className="w-80 ">
          <Venue4YouLogo />
        </div>
        <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
          <CardTitle className="text-center text-2xl font-bold">Register</CardTitle>
          <CardDescription className="text-center text-sm">
            Do you already have an account?{' '}
            <Link to="/login" className="underline">
              Login here.
            </Link>
          </CardDescription>
          <Separator />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First name</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last name</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="organization"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="userName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="example@gmail.com" autoComplete="username" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input type="password" placeholder="" autoComplete="current-password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="space-y-2">
                <Button disabled={isLoading} size="sm" className="w-full flex justify-center items-center" type="submit">
                  <span className="flex items-center justify-center">
                    {isLoading && <Spinner className="mr-2 text-primary-foreground h-3" />}
                    {isLoading ? 'Registering...' : 'Register'}
                  </span>
                </Button>
                <FormDescription>
                  By clicking the button below, you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                </FormDescription>
              </div>
            </form>
          </Form>
        </Card>
      </div>
    </div>
  );
}
