import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { User } from '../types';
import { useToast } from '@/components/ui/use-toast';
import storage from '@/utils/storage';

export type CreateUserDTO = {
  userName: string;
  organization: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  embedUrl?: string;
};

export const createUser = (data: CreateUserDTO): Promise<User> => {
  const venueGroupId = storage.getUser()?.venueGroupId;

  const embedUrl = window.location !== window.parent.location ? 'https://jirango.com/cview/web/a6fdab00' : window.location.origin;

  return axios.post(`/user/createOrUpdate`, { ...data, venueGroupId, embedUrl });
};

type UserCreateUserOptions = {
  config?: MutationConfig<typeof createUser>;
};

export const userCreateUser = ({ config }: UserCreateUserOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async newUser => {
      await queryClient.cancelQueries(['users']);

      const previousUsers = queryClient.getQueryData<User[]>(['users']);

      queryClient.setQueryData(['users'], [...(previousUsers || []), newUser]);

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData(['users'], context.previousUsers);
      }
      toast({
        variant: 'destructive',

        title: 'Something went wrong!',
      });
    },
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
      queryClient.refetchQueries(['users']);

      toast({
        variant: 'success',
        title: `User ${data.userName} added successfully!`,
      });

      window.location.pathname = '/users';
    },
    ...config,
    mutationFn: (data: any) =>
      createUser({
        embedUrl: data.embedUrl,
        userName: data.username,
        organization: data.organization,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
      }),
  });
};
