// import { lazyImport } from '@/utils/lazyImport';
import { Suspense, useEffect } from 'react';
import { Outlet, RouteObject, useLocation } from 'react-router-dom';

// Does not work well with embedded iframe
// const { Login } = lazyImport(() => import('@/features/auth/routes/Login'), 'Login');
// const { ForgotPassword } = lazyImport(() => import('@/features/auth/routes/ForgotPassword'), 'ForgotPassword');
// const { RestorePassword } = lazyImport(() => import('@/features/auth/routes/RestorePassword'), 'RestorePassword');
// const { Confirm } = lazyImport(() => import('@/features/auth/routes/Confirm'), 'Confirm');

// const { Register } = lazyImport(() => import('@/features/auth/routes/Register'), 'Register');

import { Login } from '@/features/auth/routes/Login';
import { ForgotPassword } from '@/features/auth/routes/ForgotPassword';
import { RestorePassword } from '@/features/auth/routes/RestorePassword';
import { Confirm } from '@/features/auth/routes/Confirm';
import { Register } from '@/features/auth/routes/Register';
import { useLocationStore } from '@/stores/locationStore';

const App = () => {
  const setLocation = useLocationStore(state => state.setLocation);
  const location = useLocation();

  // Trigger route change so I resize the iframe when embedded
  useEffect(() => {
    setLocation(location.pathname);
  }, [location]);

  return (
    <Suspense fallback={<div className="h-full w-full flex items-center justify-center"></div>}>
      <Outlet />
    </Suspense>
  );
};

export const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '*', element: <Login /> },
      { path: '/', element: <Login /> },
      {
        path: '/login',
        element: <Login />,
      },

      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/reset/:id', element: <RestorePassword /> },
      { path: '/confirm/:id', element: <Confirm /> },
      { path: '/register', element: <Register /> },
    ],
  },
];
