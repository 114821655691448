import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Venue } from '../types';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Check, Clock, Edit2, Image, MoreHorizontal, Trash2, X } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { DeleteButton } from '@/components/DeleteButton';
import { Separator } from '@/components/ui/separator';

interface VenueCardProps {
  venue: Venue;
  onDelete: (venue: Venue) => void;
}

export function EditVenueCard({ venue, onDelete }: VenueCardProps) {
  const headerImage = venue.venueExtra?.find(extra => extra.name === 'main')?.value;

  return (
    <Card className="bg-white shadow-md rounded-lg flex flex-col justify-between border border-input overflow-hidden">
      <div>
        <div className="h-fit relative">
          {venue.awaitingReview && (
            <span className="text-sky-800 flex font-semibold text-xs items-center absolute bottom-2 bg-sky-50 p-1  px-3 rounded-full left-2">
              <Clock className="h-4 w-4 mr-1 text-sky-600" />
              Is a waiting review
            </span>
          )}
          {venue.published ? (
            <span className="text-xs text-green-800 font-semibold flex items-center absolute top-2  bg-green-50 p-1  px-3 rounded-full left-2">
              <Check className="h-4 w-4 mr-1 text-green-600" />
              Published
            </span>
          ) : (
            <span className="text-xs text-amber-800 font-semibold flex items-center absolute top-2  bg-amber-50 p-1  px-3 rounded-full left-2">
              <X className="h-4 w-4 mr-1 text-amber-600 " />
              Unpublished
            </span>
          )}
          {headerImage ? (
            <img src={headerImage} alt={venue.venueName} className="w-full h-48 object-cover" />
          ) : (
            <div className="w-full h-48 bg-accent flex items-center justify-center">
              <Image className="h-12 w-12 m-auto text-muted-foreground" />
            </div>
          )}
        </div>

        <CardContent className="py-4 px-4 flex items-center justify-between w-full">
          <h2 className="text-md font-bold truncate">{venue.venueName}</h2>
          {/* <DropdownMenu>
            <DropdownMenuTrigger>
              <Button type="button" size="sm" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <Link className="flex items-center" to={`/venues/edit/${venue.venueId}`}>
                <DropdownMenuItem>
                  <Edit2 className="mr-2 h-4 w-4" />
                  <span>Edit</span>
                </DropdownMenuItem>
              </Link>
              <Separator />
              <DeleteButton
                onDelete={() => onDelete(venue)}
                ButtonComponent={
                  <div className="text-destructive flex items-center px-2 py-1">
                    <Trash2 className="mr-2 h-4 w-4" />
                    <span>Delete</span>
                  </div>
                }
              />
            </DropdownMenuContent>
          </DropdownMenu> */}
          <div className="flex justify-between -mr-4">
            <Link className="flex items-center" to={`/venues/edit/${venue.venueId}`}>
              <Edit2 className="mr-2 h-4 w-4" />
            </Link>
            <DeleteButton
              onDelete={() => onDelete(venue)}
              ButtonComponent={
                <div className="text-destructive flex items-center px-2 py-1">
                  <Trash2 className="mr-2 h-4 w-4" />
                </div>
              }
            />
          </div>
        </CardContent>
      </div>
    </Card>
  );
}
