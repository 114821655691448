import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Filters } from '../types';

interface HotelFiltersProps {
  filters: Filters;
  onChange: (key: string, value: string) => void;
  disabled?: boolean;
}

const hotelFilters = [
  {
    title: 'On-site hotel rooms',
    value: 'onsiteHotelRooms',
    options: [
      { value: 'empty', label: 'Select option...' },
      { value: '1TO100', label: '1-100' },
      { value: '100TO200', label: '100-200' },
      { value: '200TO500', label: '200-500' },
      { value: '500TO1000', label: '500-1000' },
      { value: 'GREATEROREQUAL1000', label: '1000 or more' },
    ],
  },
];

export function HotelFilters({ filters, onChange }: HotelFiltersProps) {
  return (
    <div className="space-y-2">
      <div>
        {hotelFilters.map(filter => (
          <div key={filter.value} className="flex flex-col items-start space-y-2 mb-4">
            <label className="font-semibold text-sm">{filter.title}</label>
            <Select value={(filters?.[filter.value as keyof typeof filters] as string) ?? 'empty'} onValueChange={value => onChange(filter.value, value)}>
              <SelectTrigger >
                <SelectValue placeholder="Select a fruit" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {filter.options.map(option => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        ))}
      </div>
    </div>
  );
}
