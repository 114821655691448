import { Link, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { BadgeCheck, SearchX, XCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Venue4YouLogo } from '@/components/Venue4YouLogo';
import { confirmEmail } from '../api/confirmEmail';
import { cn } from '@/utils';

const statusIcon = {
  confirmed: <BadgeCheck size={48} />,
  'not-found': <SearchX size={48} />,
  error: <XCircle size={48} />,
};

export function Confirm() {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'confirmed' | 'not-found' | 'error' | null>(null);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await confirmEmail({ token: id as string });
        if (response.status === 200) {
          setStatus('confirmed');
          setMessage('User confirmed!');
        }
      } catch (error: any) {
        if (error?.response.status === 404) {
          setStatus('not-found');
          setMessage('Not found...');
        }
        if (error?.response.status === 500) {
          setStatus('error');
          setMessage('An error occurred while confirming the account');
        }
      }
    })();
  }, [id]);

  return (
    <div className={cn('bg-zinc-100 h-screen w-full flex flex-col items-center justify-center', window.location === window.parent.location ? 'h-screen' : 'h-full  py-12')}>
      {window.location === window.parent.location && (
        <div className="w-80 mx-auto mb-4">
          <Venue4YouLogo />
        </div>
      )}
      <div className="space-y-4 text-center">
        <div className="space-y-2  flex flex-col items-center">
          {status && statusIcon[status]}
          <h3 className="text-lg font-medium">{message || 'User confirmed!'}</h3>
          {status === 'not-found' && <p>The account could already be confirmed or the token is invalid. Please login</p>}
        </div>
        <div>
          <Link to="/login">
            <Button>Log in</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
