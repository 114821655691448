import { DeleteButton } from '@/components/DeleteButton';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { User } from '../types';
import { Clock, Edit2, Home, Mail, Trash2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import storage from '@/utils/storage';

interface UserListItemProps {
  user: User;
  onDelete: (user: User) => void;
}

export function UserListItem({ user, onDelete }: UserListItemProps) {
  const { userId } = storage.getUser();

  return (
    <Card className="flex gap-x-2 w-full max-w-6xl mx-auto items-start bg-card p-4 rounded-md border border-input">
      <Avatar>
        <AvatarFallback className="bg-brand/80 text-white">{user.userName.slice(0, 2).toUpperCase()}</AvatarFallback>
      </Avatar>

      <div className="flex w-full ml-4">
        <div className="space-y-1">
          {user.emailConfirmationWaiting && (
            <span className="text-xs text-sky-600 flex items-center ">
              <Clock className="h-3 w-3 mr-1" />
              Email confirmation waiting
            </span>
          )}
          <span className="flex">
            {`${user.firstName} ${user.lastName}`}
            {user.title && ` • ${user.title}`}
          </span>
          <span className="text-muted-foreground text-xs flex items-center">
            <Mail className="h-3 w-3 mr-1" />
            {user.userName}
          </span>
          {user.organization !== '' && (
            <span className="text-muted-foreground text-xs flex items-center">
              <Home className="h-3 w-3 mr-1" />
              {user.organization}
            </span>
          )}
        </div>
        <div className="ml-auto flex gap-x-2 items-center">
          <Link className="flex items-center" to={`/users/edit/${user.userId}`}>
            <Edit2 className="mr-2 h-4 w-4" />
          </Link>
          <DropdownMenuSeparator />
          {userId !== user.userId && (
            <DeleteButton
              onDelete={() => onDelete(user)}
              ButtonComponent={
                <div className="text-destructive flex items-center px-2">
                  <Trash2 className="mr-2 h-4 w-4" />
                </div>
              }
            />
          )}
        </div>
      </div>
      {/* <DropdownMenu>
        <DropdownMenuTrigger>
          <Button type="button" size="sm" variant="ghost">
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>
            <Link className="flex items-center" to={`/users/edit/${user.userId}`}>
              <Edit2 className="mr-2 h-4 w-4" />
              Edit
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DeleteButton
            onDelete={() => onDelete(user)}
            ButtonComponent={
              <div className="text-destructive flex items-center px-2">
                <Trash2 className="mr-2 h-4 w-4" />
                <span>Delete</span>
              </div>
            }
          />
        </DropdownMenuContent>
      </DropdownMenu> */}
    </Card>
  );
}
