import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { Location } from '../types'; // Assuming you've updated the type name here

export type UpsertLocationDTO = {
  locationId?: number;
  locationName: string;
  parentLocationId: number;
};

export const upsertLocation = (data: UpsertLocationDTO): Promise<Location> => {
  return axios.post(`/location`, {
    ...data,
  });
};

type UseUpdateLocationDTO = {
  config?: MutationConfig<typeof upsertLocation>;
};

export const useUpsertLocation = ({ config }: UseUpdateLocationDTO) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
      });
    },
    ...config,
    mutationFn: (data: any) =>
      upsertLocation({
        ...data,
      }),
  });
};
