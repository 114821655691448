import { Button } from '@/components/ui/button';
import { DatePickerWithRange } from '@/components/ui/dateRangePicker';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { cn } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateRange } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useCreateQuote } from '../api/createQuote';
import storage from '@/utils/storage';
import { useState } from 'react';

const formSchema = z.object({
  userName: z.string().min(1),
  venueId: z.number(),
  numberOfEventDays: z.array(z.number().refine(value => value !== undefined, { message: 'You have to select how many event days' })).nonempty('This field is required'),
  eventStartTime: z.date(),
  eventEndTime: z.date(),
  typeOfEvent: z.string().refine(value => value !== undefined, { message: 'You have to select type of event' }),
  possibleOfEventDays: z.array(z.string().refine(value => value !== undefined, { message: 'You have to select possbile event days' })).nonempty('This field is required'),
});

export function SendOffer() {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userName: storage.getUser()?.userName ?? '',
      venueId: Number(id),
      numberOfEventDays: [],
      eventStartTime: new Date(),
      eventEndTime: new Date(),
      typeOfEvent: '',
      possibleOfEventDays: [],
    },
  });

  const { mutateAsync: getQuote } = useCreateQuote({});

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    await getQuote({
      data: {
        ...values,
        possibleOfEventDays: values.possibleOfEventDays.join(', '),
        numberOfEventDays: values.numberOfEventDays.map(day => day.toString()).join(', '),
      },
    });
    setIsOpen(false);
  }

  console.log(form.formState.errors)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={isOpen => {
        setIsOpen(isOpen);
      }}
    >
      <DialogTrigger>
        <Button variant="default" className="bg-brand">
          Get a quote
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="mb-4">Request a quote</DialogTitle>
          <DialogDescription>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="numberOfEventDays"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-start justify-between">
                      <FormLabel>Number of event days</FormLabel>
                      <ToggleGroup
                        onValueChange={value => field.onChange(value.map(day => parseInt(day)))}
                        variant="outline"
                        value={field.value.map(day => day.toString())}
                        type="multiple"
                      >
                        {['1', '2', '3', '4', '5'].map(day => (
                          <ToggleGroupItem
                            value={day.toString()}
                            className={cn(field.value.includes(Number(day)) && 'border bg-primary text-primary-foreground')}
                            onSelect={() => {
                              const index = field.value.indexOf(Number(day));
                              if (index === -1) {
                                field.onChange([...field.value, Number(day)]);
                              } else {
                                field.onChange(field.value.filter((_, i) => i !== index));
                              }
                            }}
                            aria-label={`Toggle ${day}`}
                          >
                            {day}
                          </ToggleGroupItem>
                        ))}
                      </ToggleGroup>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="possibleOfEventDays"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-start justify-between">
                      <FormLabel>Desired event days</FormLabel>
                      <ToggleGroup variant="outline" onValueChange={field.onChange} value={field.value.map(day => day.toString())} type="multiple">
                        {['Mon', 'Tues', 'Wedens', 'Thurs', 'Fri', 'Satur', 'Sun'].map(day => (
                          <ToggleGroupItem value={day.toString()} aria-label={`Toggle ${day}`}>
                            {day}
                          </ToggleGroupItem>
                        ))}
                      </ToggleGroup>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="eventStartTime"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Date range</FormLabel>
                      <DatePickerWithRange
                        onChange={(range: any | DateRange | undefined) => {
                          const from = range?.from ?? new Date();
                          field.onChange(from);
                          form.setValue('eventEndTime', range?.to ?? from);
                        }}
                        range={{
                          from: form.watch('eventStartTime'),
                          to: form.watch('eventEndTime'),
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="typeOfEvent"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Type of event</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select a event type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="any">Any</SelectItem>
                            <SelectItem value="exhibition">Exhibition</SelectItem>
                            <SelectItem value="exhibitionWithConference">Exhibition with conference</SelectItem>
                            <SelectItem value="conferenceCongress">Conference/congress</SelectItem>
                            <SelectItem value="conferenceCongressWithExhibition">Conference/congress with exhibition</SelectItem>
                            <SelectItem value="corporateEvent">Corporate event</SelectItem>
                            <SelectItem value="hotelBasedEvent">Hotel-based event</SelectItem>
                            <SelectItem value="otherEvent">Other event...</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit">Send</Button>
              </form>
            </Form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
